import React, {useEffect, useState} from "react";
import Table from "react-bootstrap/Table";
import {useDispatch, useSelector} from 'react-redux';
import {changeSelectedAmountField, selectPortfolioState} from '../../reducer/portfolioSlice';
import THSort from "./THSort";
import {AMOUNT_CONTROLS, CO2, getAmountControlsForFunds, LEGENDS, REAL_ESTATE_THEMES} from "./CanopiaUtils";
import FlatSelect from "./FlatSelect";
import RealEstateRow from "./RealEstateRow";
import {selectClientState} from "../../reducer/clientSlice";
import LabelWithTooltip from "./LabelWithTooltip";
import {clearAllRealEstateFilters, filterRealEstatePositions, selectRealEstateState, setTgtData} from "../../reducer/realEstateSlice";
import THFilter from "./THFilter";
import FilterResult from "./FilterResult";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {PageSelector} from "../core/PageSelector";

export default function RealEstate(props) {

    const PAGE_SIZE = 50;

    const dateSelect = props.dateSelect;
    // pagination
    const [page, setPage] = useState(1);

    const [theme, setTheme] = useState(REAL_ESTATE_THEMES[0]);
    const themes = REAL_ESTATE_THEMES;
    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();

    const {clientConfig} = useSelector(selectClientState);
    const {pfData, date, selectedAmountField} = useSelector(selectPortfolioState);
    const {tgtDataOrig, tgtData} = useSelector(selectRealEstateState);

    useEffect(() => {
        if (pfData) {
            dispatch(setTgtData({pfData, date}));
        }
    }, [pfData, date, dispatch]);

    const pfBgClass = 'background-light';
    const borderLeftClassName = 'canopia-border-left';

    let colorClass = 'canopia2';

    const adjAmountField = selectedAmountField.key === 'relPct' ? AMOUNT_CONTROLS[1] : selectedAmountField;
    const amountFields = getAmountControlsForFunds(clientConfig.showAmounts, clientConfig.clientWrapper.currency);

    let amountControlDiv = amountFields.length > 1 ?
        <FlatSelect enums={amountFields} curValue={adjAmountField} curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
                    clickFunction={changeAmountField} clickParamFromEnum={true}/>
        : amountFields[0].label;

    function changeAmountField(field) {
        dispatch(changeSelectedAmountField({amountField: field}));
    }

    function changePage(page) {
        setPage(page.key);
    }

    function changeTheme(theme) {
        setTheme(theme);
    }

    const targetSort = "realEstatePositions";

    let rows;
    let displayed = 0;
    let effPage = page;
    let message;
    let pageSelector = "";
    if (tgtData && tgtData.length > 0) {

        let startIndex = (effPage - 1) * PAGE_SIZE;
        if (startIndex >= tgtData.length) {
            effPage = 1;
            startIndex = (effPage - 1) * PAGE_SIZE;
        }
        const tgtDataAtPage = tgtData.slice(startIndex, startIndex + PAGE_SIZE);

        displayed = tgtData.length;

        let rowId = 0;
        rows = tgtDataAtPage.map(pfRow => {
            return <RealEstateRow key={rowId++} row={pfRow} theme={theme}/>
        });

        pageSelector = <PageSelector page={effPage} pageSize={PAGE_SIZE} size={tgtData.length}
                                               clickFunc={changePage}/>;
    } else {
        message = <div style={{width: '100%', padding: '10px 0 0 10px'}}><b>No data found</b></div>;
    }

    const total = tgtDataOrig ? tgtDataOrig.length : 0;

    const showClearAllFilters = displayed !== total;

    function clearAllFilters() {
        dispatch(clearAllRealEstateFilters());
    }

    let themeHeaders1, themeHeaders2;
    switch (theme.key) {
        case themes[0].key:
            themeHeaders1 = <>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={3}>
                    Actual
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={2}>
                    2030
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={1}>
                    2050
                </th>
            </>;
            themeHeaders2 = <>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                    <span className={'float-right'}>
                        <LabelWithTooltip label={<>{CO2} Perf.</>} text={LEGENDS["ratingCO2Total"]}/>
                    </span>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <span className={'float-right'}>
                        <LabelWithTooltip label={<>Heating Perf.</>} text={LEGENDS["ratingCO2Heating"]}/>
                    </span>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <span className={'float-right'}>
                        <LabelWithTooltip label={'Obsolescence'} text={LEGENDS["ratingObsolescence"]}/>
                    </span>
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                    <span className={'float-right'}>
                        <LabelWithTooltip label={<>{CO2} Needed</>} text={LEGENDS["ratingCO2Needed2030"]}/>
                    </span>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <span className={'float-right'}>
                        <LabelWithTooltip label={<>{CO2} Planned</>} text={LEGENDS["ratingCO2Planned2030"]}/>
                    </span>
                </th>
                {/*<th className={colorClass + " " + pfBgClass}><span className={'float-right'}>Projected Works</span></th>*/}
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                    <span className={'float-right'}>
                        <LabelWithTooltip label={<>{CO2} Planned</>} text={LEGENDS["ratingCO2Planned2050"]}/>
                    </span>
                </th>
            </>
            break;
        case themes[1].key:
            themeHeaders1 = <>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={4}>
                    Actual
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={2}>
                    2030
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={2}>
                    2050
                </th>
            </>;
            themeHeaders2 = <>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                    <span className={'float-right'}>
                        <THSort target={targetSort} colName="co2IDC" label="IDC" showLegend={true}/>
                    </span>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <span className={'float-right'}>
                        <THSort target={targetSort} colName="totalCO2" label={<>Total {CO2}</>} showLegend={true}/>
                    </span>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <span className={'float-right'}>
                        <THSort target={targetSort} colName="totalCO2BySRE" label={<>{CO2} / SRE</>} showLegend={true}/>
                    </span>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <span className={'float-right'}>
                        <THSort target={targetSort} colName="totalCO2ByRevenues" label={<>{CO2} / Rev.</>} showLegend={true}/>
                    </span>
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                    <span className={'float-right'}>
                        <THSort target={targetSort} colName="co2Reduction" label={<>{CO2} Red.</>} showLegend={true}/>
                    </span>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <span className={'float-right'}>
                        <THSort target={targetSort} colName="co2BySRETgt2030" label={<>Target {CO2} / SRE</>} showLegend={true}/>
                    </span>
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                    <span className={'float-right'}>
                        <THSort target={targetSort} colName="co2YearlyExcess" label={<>Yearly Excess {CO2}</>} showLegend={true}/>
                    </span>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <span className={'float-right'}>
                        <THSort target={targetSort} colName="co2ExcessTotal" label={<>Excess Total {CO2}</>} showLegend={true}/>
                    </span>
                </th>
            </>
            break;
        case themes[2].key:
            themeHeaders1 = <>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={theme.cols}>
                    Actual
                </th>
            </>;
            themeHeaders2 = <>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                    <THFilter targetSort={targetSort} sortCol="buildingId" filterCol="buildingId" label="Building ID" showLegend={false} selectFunc={selectRealEstateState}
                              filterFunc={filterRealEstatePositions}/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THFilter targetSort={targetSort} sortCol="zip" filterCol="zip" label="ZIP" showLegend={false} selectFunc={selectRealEstateState}
                              filterFunc={filterRealEstatePositions}/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THFilter targetSort={targetSort} sortCol="type" filterCol="type" label="Housing Type" showLegend={false} selectFunc={selectRealEstateState}
                              filterFunc={filterRealEstatePositions}/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THFilter targetSort={targetSort} sortCol="primaryEnergy" filterCol="primaryEnergy" label="Primary Energy" showLegend={false} selectFunc={selectRealEstateState}
                              filterFunc={filterRealEstatePositions}/>
                </th>
                <th className={colorClass + " " + pfBgClass} width={"30%"}>
                    <THFilter targetSort={targetSort} sortCol="label" filterCol="label" label="Label" showLegend={false} selectFunc={selectRealEstateState}
                              filterFunc={filterRealEstatePositions}/>
                </th>
                {/*<th className={colorClass + " " + pfBgClass}>*/}
                {/*    <span className={'float-right nowrap'}>Ref. Year</span>*/}
                {/*</th>*/}
            </>
            break;
        default:
        // console.log('unknown theme key ' + theme.key);
    }

    const showHideModal = (value) => {
        setShowModal(value);
    };

    return (
        <div>
            <Table hover className={'portfolio sticky-thc'}>
                <thead>
                <tr>
                    <th className={colorClass + " " + pfBgClass} style={{width: '100px'}}>
                        Holdings
                    </th>
                    <th className={colorClass + " " + pfBgClass + " form-padding"} style={{width: '5%'}}>
                        {dateSelect}
                    </th>
                    <th className={colorClass + " " + pfBgClass} style={{width: '10%'}} colSpan={3}>
                        <div style={{display: "flex", flexWrap: "nowrap", float: "right"}}>
                            {pageSelector}
                        </div>
                    </th>
                    <th className={colorClass + " " + pfBgClass + " form-padding"} colSpan={2} style={{width: '15%'}}>
                        <FilterResult showClearButton={showClearAllFilters}
                                      clearFunc={clearAllFilters}
                                      displayed={displayed}
                                      total={total}>
                        </FilterResult>
                    </th>
                    <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={theme.cols - 2}>
                        <FlatSelect enums={REAL_ESTATE_THEMES} curValue={theme} curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
                                    clickFunction={changeTheme} clickParamFromEnum={true}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass + " form-padding"} colSpan={2}>
                        <Button onClick={() => showHideModal(true)} style={{float: 'right'}}>Immo portal</Button>
                    </th>
                </tr>
                <tr>
                    <th className={colorClass + " " + pfBgClass} colSpan={2} rowSpan={2}>
                        <THSort target={targetSort} colName="name" label="Name"/>
                    </th>
                    <th className={colorClass + " " + pfBgClass} rowSpan={2}>
                        <THFilter targetSort={targetSort} sortCol="city" filterCol="city" label="City" showLegend={false} selectFunc={selectRealEstateState}
                                  filterFunc={filterRealEstatePositions}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass} rowSpan={2}>
                        <THFilter targetSort={targetSort} sortCol="canton" filterCol="canton" label="Canton" showLegend={false} selectFunc={selectRealEstateState}
                                  filterFunc={filterRealEstatePositions}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass} rowSpan={2}>
                        <span className={'float-right'}>
                            <THSort target={targetSort} colName="sre" label="SRE" showLegend={true}/>
                        </span>
                    </th>
                    <th className={colorClass + " " + pfBgClass} rowSpan={2}>
                        <span className={'float-right'}>
                            <THSort target={targetSort} colName="weight" label={amountControlDiv}/>
                        </span>
                    </th>
                    <th className={colorClass + " " + pfBgClass} rowSpan={2}>
                        <span className={'float-right'}>
                            <THFilter targetSort={targetSort} sortCol="consensusRank" filterCol="consensusLabel" label="ESG Consensus" showLegend={true} selectFunc={selectRealEstateState}
                                      filterFunc={filterRealEstatePositions}/>
                        </span>
                    </th>
                    {themeHeaders1}
                </tr>
                <tr>
                    {themeHeaders2}
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Table>
            {message}
            <Modal size="lg" show={showModal} onHide={() => showHideModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title><h3 className={'canopia'}>Information</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className={'canopia2'} style={{margin: '10px', fontSize: '14px'}}>You will be redirected to the website of our partner <b>Signa-Terre S.A.</b>, which is solely and
                        exclusively responsible for its access, content and use. Please take note of the term of use and comply with them.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => showHideModal(false)}>
                        Cancel
                    </Button>
                    <a href='https://immotoolsapp.signa-terre.ch/PortalV2/Login.aspx' target="_blank" rel="noreferrer">
                        <Button variant="primary" onClick={() => showHideModal(false)} style={{marginLeft: "8px"}}>
                            Open
                        </Button>
                    </a>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
