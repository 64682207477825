import React, {useState} from "react";
import {getAmountValue, getSubRowsForHisto, hasCoverage, HIDE, SHOW} from './CanopiaUtils';
import {useDispatch, useSelector} from "react-redux";
import {expandAllSubRows, focusOnRow, selectPortfolioState, showHideSubRows} from "../../reducer/portfolioSlice";
import {HiChevronDoubleDown, HiChevronDown, HiChevronRight} from "react-icons/hi";
import {IconContext} from "react-icons";
import ESGMetricsValues from "./ESGMetricsValues";
import {SecuredDownloadLink} from "./SecuredDownloadLink";
import {selectClientState} from "../../reducer/clientSlice";
import NameTruncWithSearch from "./NameTruncWithSearch";
import {BarChart, Filter, X} from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DetailChartContainer from "./chart/DetailChartContainer";
import Grade from "./Grade";
import GradeTarget from "./suitability/GradeTarget";
import ExclusionTarget from "./suitability/ExclusionTarget";
import ImpactTarget from "./suitability/ImpactTarget";
import CO2Target from "./suitability/CO2Target";
import NameTrunc from "./NameTrunc";

export default function PortfolioHistoRow(props) {

    // Roles
    const isReview = props.isReview;
    const isDev = props.isDev;

    // Portfolio
    const pfRows = props.rows; // ! Contains all rows for this pf, not only the ones displayed in the history tab
    const rowKey = props.rowKey;
    const depth = props.depth;
    const viewMode = props.viewMode;
    const showOldPos = props.showOldPos;
    const dateSelect = props.dateSelect;
    const changePfDate = props.changePfDate;

    // Sustainability theme
    const themes = props.themes;
    const theme = props.theme;
    const subTheme = props.subTheme;
    const subThemeValue = props.subThemeValue;

    // Functions
    const changeTheme = props.changeTheme;
    const changeSubTheme = props.changeSubTheme;
    const changeSubThemeValue = props.changeSubThemeValue;
    const clearSubThemeValue = props.clearSubThemeValue;

    const [chartModalDisplay, setChartModalDisplay] = useState("hidden");

    const dispatch = useDispatch();

    const {clientConfig} = useSelector(selectClientState);
    const {
        selectedAmountField,
        pfRowStatus,
        curRowKeyFocus,
        searchString,
        date,
        histoDates
    } = useSelector(selectPortfolioState);

    const showHideChildren = () => {
        dispatch(showHideSubRows({key: rowKey}));
    }

    const expandAll = () => {
        dispatch(expandAllSubRows({key: rowKey}));
    }

    const focusOnCategory = () => {
        dispatch(focusOnRow({key: rowKey}));
    }

    let lastAvailableRow;
    for (let j = 0; j < pfRows.length; j++) {
        const rootPf = pfRows[j];

        // Make sure the pf is part of the required history dates (to have at least 1 rating), skip otherwise
        if (histoDates.includes(rootPf.dateFmt) && rootPf.dateFmt <= date) {
            lastAvailableRow = rootPf;
            break;
        }
    }

    if (!lastAvailableRow) {
        // This category is not present in this part of the histo
        return null;
    }

    const category = lastAvailableRow.category;

    // hidden display -> hidden children
    let rowStatus = pfRowStatus[rowKey];
    let display;
    if (rowStatus == null) {
        console.log("row status is null for " + rowKey);
        // Show only level 1 & 2 by default
        // display = depth > 2 ? HIDE : SHOW;
        // let showChildren = depth > 1 ? HIDE : SHOW;
        // rowStatus = {status: {display: display, showChildren: showChildren}, detail: {category: category, depth: depth, parent: parentKey}};
        // Should not be called as it has been set on
        // dispatch(setPfRow({key: rowKey, status: rowStatus}));
    } else {
        display = rowStatus.status.display;
    }

    // Get the next sub rows, matching by category
    let subRows = getSubRowsForHisto(pfRows);

    // Hidden row, no display but still need to set the pfRow state (setPfRow)
    if (display === HIDE) {
        return <></>;
        // return <>
        //     {subRows.map(subRow => {
        //         return <PortfolioHistoRow key={subRow.key} rows={subRow.value} histoDates={histoDates} depth={depth + 1} rowKey={subRow.key} parentKey={rowKey}
        //                                   theme={theme} subTheme={subTheme}/>
        //     })}
        // </>
    }

    // This row is no longer part of the latest pf
    let lastDate = lastAvailableRow.dateFmt;
    let isRemovedRow = histoDates[0] !== lastDate;
    if ((viewMode.key !== 'hi' && isRemovedRow) || (viewMode.key === 'hi' && isRemovedRow && !showOldPos)) {
        return null;
    }

    // let rowKey = (props.rowKey !== "" ? (props.rowKey + "_") : "") + pfRow.category;
    let trClass = '';
    if (depth === 1) {
        trClass = "total-row";
    }
    // else if (display === HIDE) {
    //     trClass = "tr-hidden";
    // }

    // Category nav
    let expandAllIcon = "";
    let navIcon = "";
    let catClass = "";
    let missingIcons = 2;
    let focusIcon = <div>&nbsp;</div>;
    if (lastAvailableRow.subRows.length > 0) {
        // Add an underline and change the cursor to pointer when sub categories exist
        catClass = 'label-hover';
        // if (depth === 1) {
        if (lastAvailableRow.subRows[0].subRows.length > 0) {
            missingIcons--;
            expandAllIcon = <span className={catClass} style={{opacity: 0}} onClick={() => expandAll()}>
                <IconContext.Provider value={{className: "react-icons canopia2", size: "1.2em"}}>
                    <HiChevronDoubleDown/>
                </IconContext.Provider>
           </span>;
        }
        if (rowStatus.status.showChildren === SHOW) {
            navIcon = <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>
                <HiChevronDown/>
            </IconContext.Provider>;
        } else {
            navIcon = <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>
                <HiChevronRight/>
            </IconContext.Provider>;
        }
        if (depth > 1) {
            focusIcon = <span className={catClass} style={{opacity: 0}} onClick={() => focusOnCategory()}>
                {curRowKeyFocus.includes(rowKey) ?
                    <>
                        <Filter className={"react-icons canopia"} size={'1.4em'}/>
                        <X className={"react-icons canopia6"} size={'1.6em'}
                           style={{paddingBottom: "2px", marginLeft: "-14px", marginBottom: "-7px"}}/>
                    </> : <Filter className={"react-icons canopia2"} size={'1.4em'}/>
                }
                </span>;
        }
        missingIcons--;
    }

    // Amount
    let amountValue = getAmountValue(lastAvailableRow, selectedAmountField);

    let rowDetails;
    const pfEsgReport = lastAvailableRow.esgReport;
    if (isRemovedRow) {
        catClass += " category-removed";
        rowDetails =
            <>
                <td align="right">-</td>
                <td align="right">-</td>
            </>
    } else {
        rowDetails =
            <>
                <td align="right">{pfEsgReport.nbPosFmt}</td>
                <td align="right">{amountValue}</td>
            </>
    }

    let categoryCtrl;
    if (navIcon === "") {
        categoryCtrl = <span className={catClass}>
            <NameTruncWithSearch name={category} maxSize={50} search={searchString}/>
        </span>;
    } else {
        categoryCtrl = <span className={catClass} onClick={() => showHideChildren()}>
            {navIcon}<NameTruncWithSearch name={category} maxSize={55} search={searchString}/>
        </span>;
    }

    let downloadIcon = <div>&nbsp;</div>;
    if (pfEsgReport.reportAvailable && !isRemovedRow) {
        // downloadIcon = <td className={'form-padding'} onMouseOver={showCategoryControls} onMouseOut={hideCategoryControls}>
        let prefix = lastDate + '_' + clientConfig.clientWrapper.name + '_ESG Check';
        downloadIcon = <div>
            <SecuredDownloadLink client={clientConfig.clientWrapper} date={lastDate}
                                 category={lastAvailableRow.conserCategory}
                                 fileName={prefix + ' - ' + category}
                                 method='pdf'/>
        </div>;
    }

    let categoryNav = <span className={'nowrap'}>
        <span className={"td-depth-" + (depth + missingIcons)}/>
        {expandAllIcon}
        {categoryCtrl}
    </span>

    const focus = rowStatus.status.focus;
    const focusClass = focus === SHOW ? '' : 'focus-hidden';

    const showChartModal = () => {
        setChartModalDisplay("visible");
    };

    const hideChartModal = () => {
        setChartModalDisplay("hidden");
    };

    const hasCov = hasCoverage(pfEsgReport);
    const chartIcon = hasCov &&
        <span className={'label-hover'}
            // style={{opacity: 0, marginLeft: '4px'}}
              style={{marginLeft: '4px'}}
              onClick={showChartModal}>
            <IconContext.Provider value={{className: "react-icons", style: {color: '1D416D'}, size: "1.4em"}}>
                <BarChart/>
            </IconContext.Provider>
        </span>;

    function getTrend(curRow, prevRow) {
        if (hasRating(curRow) && hasRating(prevRow)) {
            const prevRank = prevRow.esgReport.ratingRank;
            const curRank = curRow.esgReport.ratingRank;
            if (curRank >= 0 && prevRank >= 0) {
                return curRank - prevRank;
            }
        }
        return 0;
    }

    function hasRating(row) {
        return row && row.esgReport && row.esgReport.rating;
    }

    let esgThemeValues;
    const borderLeftClassName = 'canopia-border-left';

    const bmRow = lastAvailableRow.benchmark;
    let bmEsgReport;
    let bmTrend;
    let bmName = '';
    if (bmRow) {
        bmName = bmRow.category;
        bmEsgReport = bmRow.esgReport;
        bmTrend = bmEsgReport.trend;
    }

    switch (viewMode.key) {
        case 'su':

            const SA_CONF = ['Bonds', 'Equities'];
            const contains = (element) => rowKey.includes(element);

            // TODO get from param
            const posImpactTargets = {
                'CT_WT': {value: 0.03, valueFmt: '3%', label: 'Climate Sectors (Clean tech + Water)', warning: false},
                'TB': {value: 0.02, valueFmt: '2%', label: 'Thematic bonds (Green + Sustainable)', warning: false}
            }
            const negImpactTargets = {
                'FF': {value: 0.03, valueFmt: '3%', label: 'Oil & Gas', warning: false},
                'FC': {value: 0.01, valueFmt: '1%', label: 'Coal', warning: false}
            }

            esgThemeValues = <>
                <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                    <Grade row={pfEsgReport} name={category} bmName={null} pfRow={pfEsgReport} bmRow={null}
                           showBm={false}/>
                </td>
                <td align={"center"} className={'form-padding'}>
                    <Grade row={bmEsgReport} name={bmName} bmName={null} pfRow={bmEsgReport} bmRow={null}
                           showBm={false}/>
                </td>
                <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                    {(rowKey === 'Portfolio' || SA_CONF.some(contains)) &&
                        <GradeTarget pfRow={pfEsgReport} pfName={category}/>}
                </td>
                <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                    {(rowKey === 'Portfolio' || SA_CONF.some(contains)) &&
                        <ExclusionTarget pfRow={pfEsgReport} pfName={category}/>}
                </td>
                <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                    {(rowKey === 'Portfolio') &&
                        <ImpactTarget pfRow={pfEsgReport} pfName={category} targets={posImpactTargets}/>}
                </td>
                <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                    {(rowKey === 'Portfolio' || ['Equities'].some(contains)) &&
                        <ImpactTarget pfRow={pfEsgReport} pfName={category} targets={negImpactTargets}/>}
                </td>
                <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                    {rowKey === 'Portfolio' && <CO2Target pfRow={pfEsgReport} pfName={category}/>}
                </td>
            </>;

            break;
        case 'pf':
            esgThemeValues = <>
                {themes.map(theme => {
                    const key = 'esg_val_' + theme.key;
                    if (theme.mainViewShow) {
                        return <ESGMetricsValues name={category} key={key}
                                                 pfEsgReport={pfEsgReport}
                                                 bmEsgReport={bmEsgReport}
                                                 pfTrend={pfEsgReport.trend}
                                                 bmClassName={'bm-hidden'}
                                                 theme={theme} themes={themes}
                                                 viewModeKey={viewMode.key}
                                                 borderLeftClassName={borderLeftClassName}/>
                    } else {
                        return <React.Fragment key={key}/>
                    }
                })}
            </>;
            break;
        case 'bm':

            esgThemeValues = <>
                <ESGMetricsValues name={category}
                                  bmName={bmName}
                                  pfEsgReport={pfEsgReport}
                                  bmEsgReport={bmEsgReport}
                                  pfTrend={pfEsgReport.trend}
                                  bmTrend={bmTrend}
                                  bmClassName={'bm-display'}
                                  theme={theme} themes={themes}
                                  subTheme={subTheme}
                                  viewModeKey={viewMode.key}
                                  borderLeftClassName={borderLeftClassName}/>

                <td className={borderLeftClassName + " background-bm-light"}>
                    <span style={{whiteSpace: "nowrap"}} className={'canopia3'}>
                        <NameTrunc name={bmName} size={40}/>
                    </span>
                </td>
            </>;

            break;

        case 'hi':
            let themeHisto = [];
            let histoDatesReverse = [...histoDates].reverse();
            let prevRow = null;
            let showRow = false;

            histoDatesReverse.forEach(date => {

                let pfRow = null;
                for (const tmpRow of pfRows) {
                    if (tmpRow.dateFmt === date) {
                        pfRow = tmpRow;
                        break;
                    }
                }

                if (pfRow) {
                    showRow = true;
                    let bmRow = pfRow.benchmark;
                    let bmName = '';
                    let bmEsgReport;
                    if (bmRow) {
                        bmName = bmRow.category;
                        bmEsgReport = bmRow.esgReport;
                    }
                    // Update the trend according to the periodicity
                    let pfTrend = getTrend(pfRow, prevRow);
                    let bmTrend = getTrend(bmRow, prevRow ? prevRow.benchmark : null);
                    themeHisto.push(<ESGMetricsValues key={'esg_val_' + date}
                                                      name={category}
                                                      bmName={bmName}
                                                      pfEsgReport={pfRow.esgReport}
                                                      bmEsgReport={bmEsgReport}
                                                      pfTrend={pfTrend}
                                                      bmTrend={bmTrend}
                                                      theme={theme}
                                                      subTheme={subTheme}
                                                      themes={themes}
                                                      viewModeKey={viewMode.key}
                                                      bmClassName={'bm-hidden'}
                                                      borderLeftClassName={borderLeftClassName}/>);
                } else {
                    themeHisto.push(
                        <React.Fragment key={"esg_review_histo_" + date}>
                            <td align="center" className={borderLeftClassName}/>
                            {subTheme && theme.subThemesHistoView === 'allFields' && theme.cols > 1 &&
                                <td align="right" colSpan={theme.cols - 1}/>}
                            {subTheme && theme.subThemesHistoView === 'singleField' && subTheme.cols > 1 &&
                                <td align="right" colSpan={subTheme.cols - 1}/>}
                        </React.Fragment>
                    );
                }
                prevRow = pfRow;
            });

            if (showRow) {
                esgThemeValues = themeHisto.reverse().map(data => {
                    return data;
                });
            } else {
                esgThemeValues = '';
            }
            break;
        default:
            // nothing
    }


    let tgtTheme, tgtSubTheme;
    tgtTheme = theme;
    tgtSubTheme = subTheme;

    const chartModal = hasCov &&
        <Modal
            // fullscreen // switch with size
            size="xl"
            show={chartModalDisplay === "visible"}
            onHide={hideChartModal}>

            <DetailChartContainer key={'esg_val_' + date}
                                  pfRowKey={lastAvailableRow.key}
                                  theme={tgtTheme}
                                  subTheme={tgtSubTheme}
                                  subThemeValue={subThemeValue}
                                  themes={themes}
                                  changeTheme={changeTheme}
                                  changeSubTheme={changeSubTheme}
                                  changeSubThemeValue={changeSubThemeValue}
                                  clearSubThemeValue={clearSubThemeValue}
                                  date={date}
                                  dateSelect={dateSelect}
                                  changePfDate={changePfDate}
                                  isReview={isReview}
                                  isDev={isDev}/>
            <Modal.Footer>
                <Button variant="secondary" onClick={hideChartModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

    return (
        <>
            <tr key={"tr_histo_" + rowKey} className={trClass + ' ' + focusClass}>
                <td colSpan={2}>{categoryNav}</td>
                <td className={'form-padding'}>
                    <div style={{display: "grid", gridTemplateColumns: "4fr 3fr 3fr"}}>
                        {focusIcon}
                        {downloadIcon}
                        {chartIcon}
                    </div>
                    {chartModal}
                </td>
                {rowDetails}
                {esgThemeValues}
            </tr>
            {subRows.map(subRow => {
                return <PortfolioHistoRow key={subRow.key}
                                          rows={subRow.value} rowKey={subRow.key} depth={depth + 1} viewMode={viewMode}
                                          showOldPos={showOldPos}
                                          theme={theme} themes={themes} subTheme={subTheme}
                                          subThemeValue={subThemeValue}
                                          changeTheme={changeTheme}
                                          changeSubTheme={changeSubTheme}
                                          changeSubThemeValue={changeSubThemeValue}
                                          clearSubThemeValue={clearSubThemeValue}
                                          dateSelect={dateSelect}
                                          changePfDate={changePfDate}
                                          isReview={isReview}
                                          isDev={isDev}
                />
            })}
        </>
    );
}
