import React, {useEffect, useState} from "react";

import {Card} from "react-bootstrap";
import {loadAllClients, selectUserState} from "../../reducer/userSlice";
import {useDispatch, useSelector} from "react-redux";
import ajaxloader from "../../images/ajax-loader.gif";
import {loadGC, selectSetupState} from "../../reducer/setupSlice";
import FlatSelect from "../canopia/FlatSelect";
import Select from "react-select";
import AdminService from "../../services/admin.service";
import GlobalParams from "./GlobalParams";
import {getClientOptionLabel, toSelectOptions} from "../canopia/CanopiaUtils";
import ViewSetup from "./ViewSetup";
import CreatableSelect from "react-select/creatable";
import GC from "./GC";
import {CiEdit} from "react-icons/ci";

export default function Setup(props) {

    const dispatch = useDispatch();

    // Data loaded on page load
    const {allClients, status, error} = useSelector(selectUserState);

    // Data loaded by dispatchLoadGC() method
    const {
        clientConfig, // clientConfig holds the view categories
        tgtDataOrig, // original global custody
        gcStatus, gcError
    } = useSelector(selectSetupState);

    const TIMEOUT = 5000;

    // Loading data
    const [loadStatus, setLoadStatus] = useState('idle');
    const [loadingDates, setLoadingDates] = useState(false);

    // Options for dropdowns
    const [clientOptions, setClientOptions] = useState([]);
    const [dateOptions, setDateOptions] = useState([]);

    // Client form
    const [clientOption, setClientOption] = useState(null);
    const [newClient, setNewClient] = useState(false);
    const [showClientInfo, setShowClientInfo] = useState(false);
    const [clientName, setClientName] = useState(null);
    const [clientDisplayName, setClientDisplayName] = useState('');
    const [clientCurrency, setClientCurrency] = useState('');
    const [saveClientInfoMessages, setSaveClientInfoMessages] = useState(null);
    const [savingClientInfo, setSavingClientInfo] = useState(false);

    const [dateOption, setDateOption] = useState(null);
    // Messages for date load
    const [messages, setMessages] = useState(null);

    const SETUP_OPTIONS = [
        {key: 'params', label: 'Global Params'},
        {key: 'views', label: 'Views'},
        {key: 'gc', label: 'Portfolio'}
    ];
    const [curSetupOption, setCurSetupOption] = useState(SETUP_OPTIONS[0]);

    // Load all the clients to populate the dropdown (done only once)
    useEffect(() => {
        if (loadStatus === "idle") {
            setSaveClientInfoMessages(null);
            setLoadStatus("pending");
            try {
                dispatch(loadAllClients());
            } catch (err) {
                setLoadStatus("error");
                // setLoadMessage("An error occurred while loading the page");
            } finally {
                setLoadStatus("success");
            }
        }
    }, [loadStatus, dispatch]);

    useEffect(() => {
        let clientOptionsTmp = [];

        allClients.forEach(client => {
            const clientId = client.id;
            const clientName = client.name;
            const clientDisplayName = client.displayName;
            // let checked = selectedUserClients.includes(clientIds);
            const option = {value: clientId, label: getClientOptionLabel(clientName, clientDisplayName, clientId)};
            clientOptionsTmp.push(option);
        });

        setClientOptions(clientOptionsTmp);

        if (clientOption && !newClient && dateOptions.length === 0) {
            loadDates(clientOption);
        }
    }, [allClients]);

    function formIsFilled() {
        return clientOption && dateOption;
    }

    function showSetup() {
        return formIsFilled() && clientConfig && clientConfig.clientWrapper.id === clientOption.value && tgtDataOrig;
    }

    function dispatchLoadGC() {
        if (formIsFilled()) {
            dispatch(loadGC({clientId: clientOption.value, dateStr: dateOption.value}));
        }
    }

    const handleLoadGC = (e) => {
        e.preventDefault();
        dispatchLoadGC();
    }

    const reloadDates = () => {
        loadDates(clientOption);
    }

    const handleSaveClientInfo = (e) => {
        e.preventDefault();
        setSaveClientInfoMessages(null);
        setSavingClientInfo(true);

        AdminService.saveClientInfo({
            clientId: newClient ? null : clientOption.value,
            clientName,
            clientDisplayName,
            clientCurrency
        }).then(
            response => {
                const respData = response.data;
                let messages;
                if (respData) {
                    messages = respData.data;
                    if (!messages.some(message => message.key === 'error')) {
                        // Successful save
                        // Filter out the client
                        const tgtOptValue = newClient ? clientName : clientOption.value;
                        let clientOptionsTmp = [...clientOptions].filter(opt => opt.value !== tgtOptValue);

                        let tgtClientId;
                        if (newClient) {
                            // Get the new ID, it sucks I know...
                            const placeholder = 'created with ID ';
                            const message = messages.find(m => m.value.includes(placeholder));
                            const split = message.value.split(placeholder);
                            tgtClientId = parseInt(split[1]);
                            messages.push({key: "INFO", value: "Please wait, the page is about to refresh..."});
                        } else {
                            tgtClientId = clientOption.value;
                        }
                        if (tgtClientId) {
                            const newClientOption = {
                                value: tgtClientId,
                                label: getClientOptionLabel(clientName, clientDisplayName, tgtClientId)
                            };
                            setClientOption(newClientOption);
                            clientOptionsTmp.push(newClientOption);
                            //Sort
                            clientOptionsTmp.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
                            if (newClient) {
                                loadDates(newClientOption);
                            }
                        } else {
                            // Issue here... show an error?
                            setClientOption(null);
                        }

                        setClientOptions(clientOptionsTmp);

                        setTimeout(() => {
                            if (newClient) {
                                setNewClient(false);
                                // Triggers a refresh of the client list (and date list)
                                // setLoadStatus("idle");
                                setDateOptions([]);
                                setDateOption(null);
                                setShowClientInfo(false);
                            }
                            setSaveClientInfoMessages(null);
                        }, TIMEOUT);
                    }
                }

                setSaveClientInfoMessages(messages);
            },
            error => {
                // processError(error);
            }
        );

        setSavingClientInfo(false);
    }

    function changeSetupOption(setupOption) {
        setCurSetupOption(setupOption);
    }

    function loadDates(client) {

        setLoadingDates(true);

        AdminService.loadClientDates({userClients: [client.value]})
            .then(response => {
                const clientDates = response.data.data;

                if (!clientDates || clientDates.length === 0) {
                    setMessages([{
                        key: "warn", value: "No portfolio found for this client, try importing one first"
                    }]);
                } else {
                    setMessages(null);
                }

                let optionsTmp = toSelectOptions(clientDates);

                setDateOptions(optionsTmp);
                if (optionsTmp.length > 0) {
                    setDateOption(optionsTmp[0]);
                }

                setLoadingDates(false);

            }, error => {
                setLoadingDates(false);
                // processError(error);
            });
    }

    const handleSelectClient = (client, action) => {
        let tgtClientOption = client;

        switch (action.action) {
            case 'create-option':
                // TODO display a modal to save first the existing new client
                // if (newClient) {
                //     setShowModal(true);
                //     return;
                // } else {
                let clientOptionsTmp = [...clientOptions];
                const newClientName = client ? client.value.trim() : null;
                setClientName(newClientName);
                const option = {value: newClientName, label: newClientName + " (NEW)"};
                tgtClientOption = option;
                clientOptionsTmp.push(option);
                setClientOptions(clientOptionsTmp);
                setNewClient(true);
                setClientDisplayName('');
                setClientCurrency('');
                setMessages(null);
                setShowClientInfo(true);
                // }
                break;
            case 'clear':
                // Only new client is clearable
                const removedValues = action.removedValues;
                if (removedValues.length > 0 && removedValues[0].label.includes("(NEW)")) {
                    // Filter out the client
                    let clientOptionsTmp = [...clientOptions].filter(opt => opt.value !== removedValues[0].value);
                    setClientOptions(clientOptionsTmp);
                }
                setNewClient(false);
                break;
            default:
                // selecting an existing client, load the available pf dates
                allClients.forEach(c => {
                    if (c.id === client.value) {
                        setClientName(c.name);
                        setClientDisplayName(c.displayName);
                        setClientCurrency(c.currency);
                    }
                });

                setNewClient(false);
                loadDates(tgtClientOption);
        }
        setClientOption(tgtClientOption);
    }

    const handleDateChange = (date, action) => {
        // setDateOption({dateOption: date});
        setDateOption(date);
    }

    let mainForm
    let loadingContent;
    let content;
    if (status === "error") {
        mainForm = <div className="dashboard">
            <header className="jumbotron">
                <h3 className='canopia'>{error ? error.error : 'Error'}</h3>
                <p>{error ? error.message : 'An error occurred while processing your request. If the problem persists, please contact our support team (info@canopia.ch)'}</p>
            </header>
        </div>
    } else if (status !== "success") {
        mainForm = <div style={{margin: "1rem auto"}}>
            <img alt="loading" src={ajaxloader}/>
        </div>;
    } else {

        mainForm = <>
            <Card style={{width: '45vw', margin: 'auto'}}>
                {/*<Card.Img variant="top" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"/>*/}
                <Card.Body>
                    <Card.Title className='canopia'>Setup</Card.Title>
                    <form onSubmit={handleLoadGC}>
                        <div className={'setup-form-row'}>
                            <div className={'setup-form-label'}>
                                <label>Client</label>
                                {clientOption && !newClient &&
                                    <CiEdit size={'1.4em'}
                                            style={{marginLeft: '1rem', cursor: 'pointer'}}
                                            onClick={() => setShowClientInfo(!showClientInfo)}/>
                                }
                            </div>
                            <div className={'setup-form-input'}>
                                <CreatableSelect options={clientOptions}
                                                 value={clientOption}
                                                 placeholder={'Select or create...'}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 closeMenuOnSelect={true}
                                                 onChange={handleSelectClient}/>
                            </div>
                        </div>
                        {!newClient && <div style={{marginTop: "1rem"}}>
                            {clientOption && <>
                                <div className={'setup-form-row'}>
                                    <div className={'setup-form-label'}>
                                        <label>Portfolio Date</label>
                                    </div>
                                    {loadingDates ? <div>
                                            <img alt="loading" src={ajaxloader}/>
                                        </div> :
                                        messages ? <div className={"setup-form-input"}>
                                            {messages.map(message => {
                                                return <div
                                                    className={"import_log_" + message.key}>{message.value}</div>
                                            })}
                                            <a className={'label-hover'} style={{padding: '3px 8px'}}
                                               onClick={reloadDates}>Refresh dates</a>
                                        </div> : <div className={'setup-form-input'} style={{width: '140px'}}>
                                            <Select options={dateOptions}
                                                    placeholder={'yyyy-MM-dd'}
                                                    name={'date-select'}
                                                    value={dateOption}
                                                    closeMenuOnSelect={true}
                                                    onChange={handleDateChange}/>
                                        </div>
                                    }
                                </div>

                                <div className="setup-form-label">
                                    <button className="btn btn-primary btn-block"
                                            disabled={loadingDates || (messages != null && messages.length > 0)}>
                                        Load
                                    </button>
                                </div>
                            </>}
                        </div>}
                    </form>
                </Card.Body>
            </Card>

            {clientOption && showClientInfo &&
                <Card style={{width: '45vw', margin: '1rem auto'}}>
                    <Card.Body>
                        <Card.Title className='canopia'>Client info</Card.Title>
                        <form onSubmit={handleSaveClientInfo}>
                            <div className={'setup-form-row'}>
                                <div className={'setup-form-label'}>
                                    <label>Name</label>
                                </div>
                                <div className={'setup-form-input'}>
                                    {clientName}
                                </div>
                            </div>
                            <div className={'setup-form-row'}>
                                <div className={'setup-form-label'}>
                                    <label>Display Name</label>
                                </div>
                                <div className={'setup-form-input'}>
                                    <input value={clientDisplayName}
                                           placeholder="A display name"
                                           className={'form-control'}
                                           aria-label="name"
                                           onChange={(event) => setClientDisplayName(event.target.value)}
                                           aria-describedby="basic-addon2"/>
                                </div>
                            </div>
                            <div className={'setup-form-row'}>
                                <div className={'setup-form-label'}>
                                    <label>Currency</label>
                                </div>
                                <div className={'setup-form-input'} style={{width: '130px'}}>
                                    <input value={clientCurrency}
                                           placeholder="CHF/EUR/USD..."
                                           className={'form-control'}
                                           aria-label="name"
                                           onChange={(event) => setClientCurrency(event.target.value)}
                                           aria-describedby="basic-addon2"/>
                                </div>
                            </div>
                            <div style={{padding: ".75em .5em 0 0", width: "100%"}}>
                                <button className="btn btn-primary btn-block" disabled={savingClientInfo}>
                                    {savingClientInfo &&
                                        <span className="spinner-border spinner-border-sm"
                                              style={{marginRight: '5px'}}/>
                                    }
                                    <span>Save</span>
                                </button>
                            </div>
                            {saveClientInfoMessages && (<div className="form-group">
                                <br/>
                                <div className={"alert import_log"}>
                                    {saveClientInfoMessages.map(message => {
                                        return <div
                                            className={"import_log_" + message.key}>{message.value}</div>
                                    })}
                                </div>
                            </div>)}
                        </form>
                    </Card.Body>
                </Card>}
        </>

        switch (gcStatus) {
            case "error":
                loadingContent = <div className="dashboard">
                    <header className="jumbotron">
                        <h3 className='canopia'>{gcError ? gcError.error : 'Error'}</h3>
                        <p>{gcError ? gcError.message : 'An error occurred while processing your request. If the problem persists, please contact our support team (info@canopia.ch)'}</p>
                    </header>
                </div>
                break;
            case "loading":
                loadingContent = <div><img alt="loading" src={ajaxloader}/></div>;
                break;
            case "idle":
                loadingContent = <div></div>;
                break;
            case "success":
                // Don't understand why gcStatus = "success" before the useEffect is called to set the clientConfig...
                if (!clientConfig) {
                    content = <>No client config found</>;
                    break;
                }
                switch (curSetupOption.key) {
                    case "params":
                        content = <GlobalParams clientConfig={clientConfig} dateOption={dateOption}/>
                        break;
                    case "views":
                        content = <ViewSetup clientOption={clientOption} dateOption={dateOption}/>
                        break;
                    case "gc":
                        content = <GC dateOption={dateOption}/>
                        break;
                    default:
                    // nothing
                }
                break;
            default:
            // nothing
        }
    }

    return <div className="main-content">
        {mainForm}

        {loadingContent}

        {showSetup() && <>
            <div style={{marginTop: '1rem'}}>
                <FlatSelect enums={SETUP_OPTIONS} curValue={curSetupOption} curClass={'canopia-nav-link-active'}
                            otherClass={'canopia-nav-link'}
                            clickFunction={changeSetupOption} clickParamFromEnum={true}/>
            </div>
            <div style={{marginTop: '1rem'}}>
                {content}
            </div>
        </>}
    </div>;
}
