import axios from "axios";
import store from "../store/store";
import {
    CANOPIA_ADMIN,
    COMPUTING_CONTEXT_URL,
    CONTEXT_URL,
    RESET_ACTION,
    TIMEOUT
} from "../containers/canopia/CanopiaUtils";
import {authHeader, oauth2Header} from "./auth-header";

const API_URL = CONTEXT_URL + "api/auth/";
const COMPUTING_API_URL = COMPUTING_CONTEXT_URL + "api/auth/";
const USER_PROP = 'user';
// const ADMIN_USER_PROP = 'AdminUser';
const LOGIN_TIME_PROP = "loginTime";

async function getIP() {
    const publicIp = require('public-ip');
    let clientIp = 'unknown';
    try {
        clientIp = await publicIp.publicIpv4();
    } catch (e) {
        console.log('Could not get the IP');
    }
    return clientIp;
}

/**
 * Provide an API to register, authenticate and disconnect a user.
 * Use the browser storage to keep the user throughout the session lifecycle.
 * Here the sessionStorage is used (allows multiple authentication on the same browser)
 * Other option: use localStorage (only 1 authentication per browser)
 * see https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
 */
class AuthService {

    async login(username, password) {
        let clientIp = await getIP();
        return axios
            .post(API_URL + "public/login-basic", {
                username,
                password,
                clientIp
            })
            .then(response => {
                this.handleAuth(response);
                // const user = this.getCurrentUser();
                // // If Admin -> log in to the computation server
                // const isCanopiaAdmin = user && user.roles.includes(CANOPIA_ADMIN);
                // if (isCanopiaAdmin) {
                //     axios
                //         .post(COMPUTING_API_URL + "public/login-basic", {
                //             username,
                //             password,
                //             clientIp
                //         })
                //         .then(response2 => {
                //             // No need to save as the user is already stored
                //             // this.handleAdminAuth(response2);
                //         });
                // }
                return response;
            });
    }

    async loginOAuth2(token) {
        let clientIp = await getIP();
        return axios
            .post(API_URL + "public/login-oauth2", {
                    token,
                    clientIp
                },
                {
                    headers: oauth2Header(token),
                    cache: 'no-store'
                }) // set the auth header as the register process is not public on server side)
            .then(response => {
                this.handleAuth(response);
                return response;
            });
    }

    handleAuth(response) {
        if (response.data.accessToken) {
            localStorage.setItem(USER_PROP, JSON.stringify(response.data));
            localStorage.setItem(LOGIN_TIME_PROP, new Date().getTime().toString());
        }
    }


    // handleAdminAuth(response) {
    //     if (response.data.accessToken) {
    //         localStorage.setItem(ADMIN_USER_PROP, JSON.stringify(response.data));
    //     }
    // }

    isExpired() {
        const storedLoginTime = localStorage.getItem(LOGIN_TIME_PROP);

        if (!storedLoginTime) {
            // The user is not logged
            return false;
        }

        const loginTime = new Date(Number.parseInt(storedLoginTime));

        return (new Date().getTime() - loginTime.getTime()) > TIMEOUT;
    }

    logout() {
        localStorage.removeItem(USER_PROP);
        localStorage.removeItem(LOGIN_TIME_PROP);
        // localStorage.removeItem(ADMIN_USER_PROP);
    }

    resetStore() {
        store.dispatch(RESET_ACTION);
    }

    async accept(username, disclaimerId) {
        return axios
            .post(API_URL + "private/accept", {
                username,
                disclaimerId
            }, {headers: authHeader(), cache: 'no-store'})
            .then(response => {
                const data = response.data;
                if (data === 'success') {
                    // update the flag, not a good practice, should reload the user from the server
                    let user = this.getCurrentUser();
                    user.disclaimerAccepted = true;
                    localStorage.setItem(USER_PROP, JSON.stringify(user));
                }
                return response;
            });
    }

    register(firstname, lastname, company, clientIds, email, password) {
        return axios.post(COMPUTING_API_URL + "private/signup",
            {
                firstname,
                lastname,
                company,
                clientIds,
                email,
                password
            },
            {headers: authHeader(), cache: 'no-store'}); // set the auth header as the register process is not public on server side
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem(USER_PROP));
    }

    // getAdminUser() {
    //     return JSON.parse(localStorage.getItem(ADMIN_USER_PROP));
    // }

    // async getCurrentUser() {
    // let user = JSON.parse(localStorage.getItem('user'));
    //
    // // No user
    // if (!user) {
    //     return null;
    // }
    //
    // // Check the timeout on server side
    // user = null;
    // try {
    //     const response = await axios.get(API_URL + "alive", {headers: authHeader()});
    //     if (response.data["data"] === 'ALIVE') {
    //         user = JSON.parse(localStorage.getItem('user'));
    //     }
    // } catch (error) {
    //     console.error(error);
    // }
    // return user;
    // }

    changePwd(username, password, isReset, pwdResetToken) {
        let config = {headers: authHeader(), cache: 'no-store'};
        let data;
        let uri;

        if (isReset) {
            uri = "public/reset-pwd";
            data = {
                username,
                password,
                token: pwdResetToken
            };
        } else {
            uri = "private/change-pwd";
            data = {
                username,
                password
            };
        }

        return axios.post(API_URL + uri,
            data,
            config); // set the auth header as the register process is not public on server side
    }

    requestResetPwd(username) {
        return axios.post(API_URL + "public/request-reset-pwd",
            {
                username
            });
    }

    async checkPwdResetToken(token, username) {
        let clientIp = await getIP();

        return axios.post(API_URL + "public/request-reset-pwd-validate-token",
            {
                token,
                username,
                clientIp
            })
            .then(response => {
                this.handleAuth(response);
                return response;
            });
    }
}


const authService = new AuthService();
export default authService;
