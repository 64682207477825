import React, {useState} from "react";
import {useSelector} from 'react-redux';
import {selectPortfolioState} from "../../reducer/portfolioSlice";
import Table from "react-bootstrap/Table";
import {SecuredDownloadLink} from "./SecuredDownloadLink";
import {selectClientState} from "../../reducer/clientSlice";
import {Folder} from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function Download(props) {

    const {clientConfig} = useSelector(selectClientState);
    const {pfData, view} = useSelector(selectPortfolioState);

    const [showModal, setShowModal] = useState(false);
    const [fileNames, setFileNames] = useState([]);
    const [dnlDate, setDnlDate] = useState(null);
    const [folder, setFolder] = useState(null);
    const [folderDesc, setFolderDesc] = useState(null);

    if (!pfData) {
        return <div>No data found for this client</div>;
    }

    const isSales = props.isSales;

    let pfView = view != null ? view : clientConfig.clientViews[0];

    let reports = [];
    const pfReports = pfData.pfReports[pfView.viewId];
    Object.entries(pfReports).forEach(([date, rootPf]) => {
        if (!rootPf.hidden) {
            reports.push(rootPf);
        }
    });

    function showFolderModal(date, fileNames, folder, description) {
        setShowModal(true);
        setDnlDate(date);
        setFolder(folder);
        setFolderDesc(description);
        setFileNames(fileNames);
    }

    function hideModal() {
        setShowModal(false);
    }

    let dnlHisto = [];
    let keyIndex = 0;
    const colorClass = 'canopia2';
    reports.forEach(pfRow => {

        let dateComp = <td>
            <div className={colorClass + ""}>
                <b>
                    <span style={{whiteSpace: "nowrap"}}>{pfRow.dateFmt}</span>
                </b>
            </div>
        </td>

        let prefix = pfRow.dateFmt + '_' + clientConfig.clientWrapper.name;

        let fileKeys = pfRow.fileKeys;

        let link1 = '';
        if (fileKeys && fileKeys.hasOwnProperty('XL')) {
            link1 = <SecuredDownloadLink client={clientConfig.clientWrapper} date={pfRow.dateFmt} fileName={prefix + '_ESG_Portfolio_Report'} method='excel'/>
        }

        let link3 = '';
        if (fileKeys && fileKeys.hasOwnProperty('ARCHIVE')) {
            link3 = <>
                <SecuredDownloadLink client={clientConfig.clientWrapper} date={pfRow.dateFmt} fileName={prefix + '_ESG_Reports'} method='archive'/> ({fileKeys['ARCHIVE']})
            </>;
        }

        let link7 = '';
        if (fileKeys && fileKeys.hasOwnProperty('SCS')) {
            link7 = <SecuredDownloadLink client={clientConfig.clientWrapper} date={pfRow.dateFmt} category='SCS' fileName={prefix + '_Swiss_Climate_Scores_Report'} method='pdf'/>;
        }

        let link4 = '';
        if (fileKeys && fileKeys.hasOwnProperty('PACTA')) {
            link4 = <SecuredDownloadLink client={clientConfig.clientWrapper} date={pfRow.dateFmt} category='PACTA' fileName={prefix + '_PACTA_Report'} method='pdf'/>;
        }

        dnlHisto.push(
            <tr key={"dnl" + keyIndex}>
                {dateComp}
                <td className={'canopia-border-left'}>
                    {link1}
                </td>
                <td className={'canopia-border-left'}>
                    {pfRow.filesByDir.hasOwnProperty("reports") &&
                        <span className={'label-hover'}
                              onClick={() => showFolderModal(pfRow.dateFmt, pfRow.filesByDir["reports"], "reports", "Reports")}
                              style={{marginRight: '3vw'}}>
                                    <Folder color={'#adad2f'} size={'1.4em'}/>
                                </span>
                    }
                </td>
                <td className={'canopia-border-left'}>
                    {link3}
                </td>
                <td className={'canopia-border-left'}>
                    {link7}
                </td>
                <td className={'canopia-border-left'}>
                    {link4}
                </td>
                {(isSales || clientConfig.showIC) && <>
                    <td className={'canopia-border-left'}>
                        {pfRow.filesByDir.hasOwnProperty("IC") &&
                            <span className={'label-hover'}
                                  onClick={() => showFolderModal(pfRow.dateFmt, pfRow.filesByDir["IC"], "IC", "Investment Controlling")}
                                  style={{marginRight: '3vw'}}>
                                    <Folder color={'#adad2f'} size={'1.4em'}/>
                                </span>
                        }
                    </td>
                </>}
                <td className={'canopia-border-left'} width={'70%'}/>
            </tr>
        );
        keyIndex++;
    });

    return (
        <>
            <Table hover size="sm" className="portfolio sticky-thc">
                <thead>
                <tr key="header1">
                    <th className={colorClass + " background-light"}>
                        <span style={{whiteSpace: "nowrap"}}>Holdings</span>
                    </th>
                    <th className={colorClass + " background-light canopia-border-left"}>
                        <span style={{whiteSpace: "nowrap"}}>Excel Report</span>
                    </th>
                    <th className={colorClass + " background-light canopia-border-left"}>
                        <span style={{whiteSpace: "nowrap"}}>Global Report</span>
                    </th>
                    <th className={colorClass + " background-light canopia-border-left"}>
                        <span style={{whiteSpace: "nowrap"}}>ESG Checks (zip)</span>
                    </th>
                    <th className={colorClass + " background-light canopia-border-left"}>
                        <span style={{whiteSpace: "nowrap"}}>Swiss Climate Scores</span>
                    </th>
                    <th className={colorClass + " background-light canopia-border-left"}>
                        <span style={{whiteSpace: "nowrap"}}>PACTA</span>
                    </th>
                    {(isSales || clientConfig.showIC) &&
                        <th className={colorClass + " background-light canopia-border-left"}>
                            <span style={{whiteSpace: "nowrap"}}>Investment Controlling</span>
                        </th>
                    }
                    <th className={colorClass + " background-light canopia-border-left"}/>
                </tr>
                </thead>
                <tbody>
                {dnlHisto.map(data => {
                    return data
                })}
                </tbody>
            </Table>
            <Modal
                show={showModal}
                onHide={() => hideModal()}
                backdrop="static"
                keyboard={true}
                size={"lg"}
                aria-labelledby="terms-and-conditions"
                scrollable={true}
            >
                <Modal.Title>
                    <div className={'detail-title'}>
                        <p className={'canopia2 detail-title-font'}>{dnlDate} / {folderDesc}</p>
                    </div>
                </Modal.Title>
                <Modal.Body>
                    {fileNames.map(fileName => {
                        return <div style={{display: "flex", flexWrap: "nowrap"}} className={'dnl-row'}>
                            <div style={{padding: '.5rem', flex: "0 0 20px"}}>
                                <SecuredDownloadLink client={clientConfig.clientWrapper} date={dnlDate} category={folder} fileName={fileName} method='pdf'/>
                            </div>
                            <div style={{padding: '.5rem', flex: "0 0 400px", marginRight: '1rem'}}>{fileName}</div>
                        </div>;
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => hideModal()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
