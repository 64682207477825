import React from "react";
import SimpleToolTip from "../SimpleToolTip";
import Table from "react-bootstrap/Table";
import {CO2, THEMES} from "../CanopiaUtils";
import {CheckCircleFill, ExclamationCircleFill} from "react-bootstrap-icons";

export default function CO2Target(props) {

    const pfName = props.pfName;
    const pfRow = props.pfRow;

    const pfConsensusRatingRank = pfRow.consRatingRank;

    const pfBgClass = 'background-light';
    const borderLeftClassName = 'border-large-left';

    // TODO get from param
    const subTheme = THEMES[3].subThemes[0];
    const targets = {
        'co2Int': {value: 143.59, valueFmt: '143.59', label: subTheme.units, itemValueKey: subTheme.valueFields[0], itemValueFmtKey: subTheme.valueFields[1], warning: false}
    }

    // let posImpacts = pfRow.posImpacts.concat(pfRow.thematicBonds).concat(pfRow.highImpacts.length ? pfRow.highImpactsSum : []);
    let warning = false;

    Object.entries(targets).forEach(([key, conf]) => {
        targets[key]['item'] = pfRow[conf.itemValueFmtKey]; // keep the exclusion associated to this target
        if (pfRow[conf.itemValueKey] >= conf.value) {
            warning = true;
            targets[key]['warning'] = true;
        }
    });

    let indicator = pfConsensusRatingRank > 0 && (warning ?
        <ExclamationCircleFill size={'1.5em'} className={'suitability-warning'}/> :
        <CheckCircleFill size={'1.5em'} className={'suitability-ok'}/>);

    let rows = [];
    Object.entries(targets).forEach(([key, conf]) => {
        let item;
        let desc = conf.label, wgt = '-';
        let icon = conf['warning'] ?
            <ExclamationCircleFill size={'1.5em'} className={'suitability-warning'}/> :
            <CheckCircleFill size={'1.5em'} className={'suitability-ok'}/>;

        if (conf.hasOwnProperty('item')) {
            item = conf['item'];
            wgt = item;
            wgt = wgt === '' ? '-' : wgt;
        }

        rows.push(<tr key={key}>
            <td className={pfBgClass}>
                {desc}
            </td>
            <td className={pfBgClass} align={"right"}>
                {icon}
            </td>
            <td className={pfBgClass} align={"right"}>
                {wgt}
            </td>
            <td className={pfBgClass + " " + borderLeftClassName} align={"right"}>
                {conf.valueFmt}
            </td>
        </tr>);
    });

    let tooltipContent = <>
        <h3 className={'canopia2'}>{pfName}</h3>
        <p className={'canopia'}><b>{CO2}</b></p>
        <Table size="sm">
            <thead>
            <tr>
                <th className={"canopia2"}>Theme</th>
                <th className={"canopia2"} colSpan={2}>
                    <span style={{float: "right"}}>Weight</span>
                </th>
                <th className={"canopia2 " + borderLeftClassName} colSpan={2}>
                    <span style={{float: "right"}}>Limit</span>
                </th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </Table>
    </>;

    return <SimpleToolTip content1={indicator} content2={tooltipContent}/>;
}
