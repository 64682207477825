import {IconContext} from "react-icons";
import {AiOutlineDownload} from "react-icons/ai";
import React, {useRef, useState} from "react";
import DownloadService from "../../services/download.service";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ajaxloader from "../../images/ajax-loader.gif";
import {useNavigate} from "react-router-dom";

/**
 * Create a link to download a file using a secure request.
 * https://blog.mellisdesigns.com/react-authenticated-file-downloads/
 */
export function SecuredDownloadLink(props) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [showPendingModal, setShowPendingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [errorName, setErrorName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showLogin, setShowLogin] = useState(null);

    const navigate = useNavigate();

    const ref = useRef(null);

    const handleClose = () => {
        setErrorName(null);
        setErrorMessage(null);
        setShow(false)
    };

    const handleShow = () => setShow(true);

    const download = async () => {
        setShowPendingModal(true);
        await doDownload();
        setShowPendingModal(false);
    }

    const doDownload = async () => {
        // if (ref.current.href) {
        //     return
        // }

        if (addRequestStatus === 'idle') {
            try {
                setAddRequestStatus('pending');

                let response;
                let type;
                let ext;
                switch (props.method) {
                    case 'template':
                        response = await DownloadService.getTemplate({fileName: props.fileName});
                        type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        ext = "";
                        break;
                    case 'excel':
                        response = await DownloadService.getExcelReport({clientId: props.client.id, date: props.date});
                        type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        ext = ".xlsx";
                        break;
                    case 'archive':
                        response = await DownloadService.getArchive({clientId: props.client.id, date: props.date});
                        type = "application/zip";
                        ext = ".zip";
                        break;
                    case 'pdf':
                        response = await DownloadService.getPdfReport({
                            clientId: props.client.id,
                            dateStr: props.date,
                            category: props.category,
                            fileName: props.fileName
                        });
                        type = "application/pdf";
                        if (['IC', 'reports'].includes(props.category)) {
                            ext = "";
                        } else {
                            // WIP: ext is about to be part of the filename, only available for IC
                            ext = ".pdf";
                        }
                        break;
                    case 'csv':
                        response = await DownloadService.getHistory({clientId: props.client.id});
                        type = "text/csv";
                        ext = ".csv";
                        break;
                    default:
                        console.log('Unsupported method ' + props.method);
                }

                let status = response.status;
                if (status && status !== 200) {
                    setErrorName(response.error);
                    let message;
                    if (status === 500) {
                        message = "An unexpected error occurred. If the problem persists, please contact our support team (info@canopia.ch)";
                    } else {
                        message = response.message;
                    }
                    setErrorMessage(message);
                    setShowLogin(status === 403);
                    handleShow();
                } else {
                    const fileName = props.fileName;
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                        window.navigator.msSaveOrOpenBlob(new Blob([response], {type: type}), fileName + ext);
                    } else { // for Non-IE (chrome, firefox etc.)
                        ref.current.download = fileName + ext;
                        ref.current.href = window.URL.createObjectURL(response);

                        ref.current.click();
                    }
                }

            } catch (err) {
                console.error('Failed to download the report: ', err)
            } finally {
                setAddRequestStatus('idle');
            }
        }
    }

    const redirect = () => {
        navigate("/login");
    }

    let link = <a className={'label-hover'} ref={ref} onClick={download}>
        <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>
            <AiOutlineDownload/>
        </IconContext.Provider>
    </a>;

    // Show a modal with the error
    if (errorName) {
        return (
            <>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className='canopia'>{errorName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMessage}
                    </Modal.Body>
                    {showLogin &&
                    <Modal.Footer>
                        <Button variant="primary" onClick={redirect}>Login</Button>
                    </Modal.Footer>}
                </Modal>
                {link}
            </>
        )
    }

    if (showPendingModal) {
        return (
            <>
                <Modal
                    show={showPendingModal}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title className='canopia'>Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Downloading, please wait</p>
                        <div style={{textAlign: 'center'}}><img alt="loading" src={ajaxloader}/></div>
                    </Modal.Body>
                </Modal>
                {link}
            </>
        )
    }

    // Just show the link
    return link;
}
