import React from "react";

/**
 Renders an horizontal mouseover drop-down filled with the given enum values.
 The options appears on the right of the active one, with a pipe separator
 Ex.: 2021  2020 | 2019
 */
export default function FlatSelect(props) {

    let enums = props.enums;
    let curValue = props.curValue;
    let curClass = props.curClass;
    let otherClass = props.otherClass;
    let clickFunction = props.clickFunction;
    let clickParamFromEnum = props.clickParamFromEnum;
    let divStyle = props.divStyle;

    if (!enums || !enums.length) {
        console.log('EnumSelector props error: ' + enums);
        return "";
    }

    let nbSep = 1;

    const span = <span className={"nowrap"}>
        {enums.map(enumElem => {
            let key = enumElem.key;

            let fieldClass;
            if (curValue.key === key) {
                fieldClass = curClass;
            } else {
                // The active element is disabled and highlighted
                fieldClass = otherClass;
            }

            // Add a separator if not the last option
            let separator = nbSep < enums.length ? <span key={key}>
                    {' | '}</span> : ''

            nbSep++;

            let functionCall = clickParamFromEnum ? () => clickFunction(enumElem) : clickFunction;

            return <React.Fragment key={key}>
                {enumElem.hasOwnProperty('disabled') ? <span key={'span' + key} className={fieldClass}>
                    {enumElem.label}
                </span> : <span key={'span' + key} className={fieldClass} onClick={functionCall}>
                    {enumElem.label}
                </span>}

                {separator}
            </React.Fragment>;
        })}
    </span>;
    return (divStyle ? <div style={divStyle}>
        {span}
    </div> : <>{span}</>);
}
