import {createSlice} from '@reduxjs/toolkit';
import {filter, getNextSort, parseNull, sort} from "../containers/canopia/CanopiaUtils";

const fundsInitialSortStatus = {
    "category": "asc",
    "isin": "desc",
    "currency": "desc",
    "structure": "desc",
    "lipperGlobalScheme": "desc",
    "manager": "desc",
    "lookthroughFormatted": "asc",
    "esgReport.nbPos": "asc",
    "weight": "asc", // Same sort than for the amount
    // "amount": "asc",
    "esgReport.ratingRank": "asc",
    "esgReport.trend": "asc",
    "esgReport.coverage": "asc",
    "esgReport.normsSum.weight": "asc",
    "esgReport.sectorsSum.weight": "asc",
    "esgReport.negImpactsSum.weight": "asc",
    "esgReport.allPosImpactsSum.weight": "asc",
    "esgReport.thematicBondsSum.weight": "asc",
    "esgReport.sbtiSum.weight": "asc",
    "esgReport.co2IntNorm": "asc",
    "esgReport.co2EmiNorm": "asc",
}

const initialState = {

    date: null, // the date at which the root pf is displayed
    // Direct lines
    tgtDataOrig: null,
    tgtData: [], // The displayed ones, filtered, sorted, ...
    sortStatus: fundsInitialSortStatus,
    sortCol: "name",
    filterValuesOrig: {}, // all the values of the filters corresponding to directLinesOrig (initialized once, immutable then)
    filterValues: {}, // all the values of the filters
    filterSelectedValues: {}, // the current selected values of the filters
}

const filterCols = { //
    'manager': 0, // 0 => the filter is inactive, 1 => 1st active filter, ...
    'lipperGlobalScheme': 0,
    'currency': 0
};

function resetFilters(state) {
    state.filterValues = state.filterValuesOrig;
    state.filterSelectedValues = state.filterValuesOrig;
    Object.keys(filterCols).forEach(filterCol => {
        filterCols[filterCol] = 0;
    });
}

export const fundsSlice = createSlice({
    name: 'funds',
    initialState,
    reducers: {
        setFunds: (state, action) => {
            state.date = action.payload.date;
            state.tgtDataOrig = action.payload.pfData.fundsReports[state.date];
            state.tgtData = state.tgtDataOrig;

            if (state.tgtDataOrig) {
                const tmpValues = {};
                Object.keys(filterCols).forEach(filterCol => {
                    tmpValues[filterCol] = [];
                });
                state.tgtDataOrig.forEach(dLine => {
                    Object.keys(filterCols).forEach(filterCol => {
                        const value = parseNull(dLine[filterCol]);
                        if (!tmpValues[filterCol].includes(value)) {
                            tmpValues[filterCol].push(value);
                        }
                    });
                });
                Object.keys(tmpValues).forEach(filterCol => {
                    tmpValues[filterCol].sort();
                });
                state.filterValuesOrig = tmpValues;

                resetFilters(state);
            }

            // filter(state);
        },
        sortFunds: (state, action) => {
            // load the portfolio of the selected client
            let col = action.payload.col;

            sort(state, col, fundsInitialSortStatus);
        },
        filterFunds: (state, action) => {
            // Update the filters with the new selection
            const colName = action.payload.colName;
            const selectedValues = action.payload.selectedValues;

            filter(state, colName, filterCols, selectedValues, fundsInitialSortStatus);
        },
        clearAllFundsFilters: (state, action) => {
            state.tgtData = state.tgtDataOrig;

            resetFilters(state);

            let prevSort = state.sortStatus[state.sortCol];
            state.sortStatus[state.sortCol] = getNextSort(prevSort);
            sort(state, state.sortCol, fundsInitialSortStatus);
        }
    }
});

export const {
    setFunds,
    filterFunds,
    sortFunds,
    clearAllFundsFilters,
} = fundsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectFundsState = state => state.funds;

export default fundsSlice.reducer;