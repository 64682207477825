import React from "react";
import SimpleToolTip from "../SimpleToolTip";
import Table from "react-bootstrap/Table";
import {CheckCircleFill, ExclamationCircleFill} from "react-bootstrap-icons";

export default function ImpactTarget(props) {

    const pfName = props.pfName;
    const pfRow = props.pfRow;
    const targets = props.targets;

    const pfConsensusRatingRank = pfRow.consRatingRank;

    const pfBgClass = 'background-light';
    const borderLeftClassName = 'border-large-left';

    // let posImpacts = pfRow.posImpacts.concat(pfRow.thematicBonds).concat(pfRow.highImpacts.length ? pfRow.highImpactsSum : []);
    let warning = false;

    Object.entries(targets).forEach(([key, conf]) => {
        switch (key) {
            case 'CT_WT':
                targets[key]['item'] = pfRow.posImpactsSum; // keep the exclusion associated to this target
                if (pfRow.posImpactsSum.weight < conf.value) {
                    warning = true;
                    targets[key]['warning'] = true;
                }
                break;
            case 'TB':
                targets[key]['item'] = pfRow.thematicBondsSum;
                if (pfRow.thematicBondsSum.weight < conf.value) {
                    warning = true;
                    targets[key]['warning'] = true;
                }
                break;
            case 'FF':
            case 'FC':

                const negImpacts = pfRow.negImpacts;
                negImpacts.forEach(theme => {
                    if (theme.label === key) {
                        targets[key]['item'] = theme;
                        if (theme.weight > conf.value) {
                            warning = true;
                            targets[key]['warning'] = true;
                        }
                    }
                })

                break;

            default:
        }

    });

    let indicator = pfConsensusRatingRank > 0 && (warning ?
        <ExclamationCircleFill size={'1.5em'} className={'suitability-warning'}/> :
        <CheckCircleFill size={'1.5em'} className={'suitability-ok'}/>);

    let rows = [];
    Object.entries(targets).forEach(([key, conf]) => {
        let item;
        let desc = conf.label, wgt = '-';
        let icon = conf['warning'] ?
            <ExclamationCircleFill size={'1.5em'} className={'suitability-warning'}/> :
            <CheckCircleFill size={'1.5em'} className={'suitability-ok'}/>;

        if (conf.hasOwnProperty('item')) {
            item = conf['item'];
            wgt = item.formattedWeight;
            wgt = wgt === '' ? '-' : wgt;
        }

        rows.push(<tr key={key}>
            <td className={pfBgClass}>
                {desc}
            </td>
            <td className={pfBgClass} align={"right"}>
                {icon}
            </td>
            <td className={pfBgClass} align={"right"}>
                {wgt}
            </td>
            <td className={pfBgClass + " " + borderLeftClassName} align={"right"}>
                {conf.valueFmt}
            </td>
        </tr>);
    });

    let tooltipContent = <>
        <h3 className={'canopia2'}>{pfName}</h3>
        <p className={'canopia'}><b>Positive Impacts</b></p>
        <Table size="sm">
            <thead>
            <tr>
                <th className={"canopia2"}>Theme</th>
                <th className={"canopia2"} colSpan={2}>
                    <span style={{float: "right"}}>Weight</span>
                </th>
                <th className={"canopia2 " + borderLeftClassName} colSpan={2}>
                    <span style={{float: "right"}}>Limit</span>
                </th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </Table>
    </>;

    return <SimpleToolTip content1={indicator} content2={tooltipContent}/>;
}
