import React, {useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {resetSearchGC, searchGC, selectSetupState} from "../../reducer/setupSlice";
import THSort from "../canopia/THSort";
import Table from "react-bootstrap/Table";
import {PageSelector} from "../core/PageSelector";
import {FormGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {IconContext} from "react-icons";
import {TiDeleteOutline} from "react-icons/ti";
import NameTruncWithSearch from "../canopia/NameTruncWithSearch";

export default function GC(props) {

    const dispatch = useDispatch();

    // const clientConfig = props.clientConfig;
    const dateOption = props.dateOption;

    const GC_PAGE_SIZE = 50;

    const {
        tgtDataOrig, // original global custody
        tgtData, // global custody shown (filtered, sorted)
    } = useSelector(selectSetupState);

    // GC pagination
    const [page, setPage] = useState(1);

    // GC search
    const [clearSearchVisibility, setClearSearchVisibility] = useState('hidden');
    const [searchString, setSearchString] = useState('');

    // const bmPosIdPrefix = "Pos_Id_"
    // const bmAccountPrefix = "Acc_Id_"
    const colorClass = 'canopia2';
    const targetSort = "gc";
    const pfBgClass = 'background-light';

    function changePage(page) {
        setPage(page.key);
    }

    // GC (last level)
    let rows;
    let effPage = page;
    if (tgtData && tgtData.length > 0) {

        let startIndex = (effPage - 1) * GC_PAGE_SIZE;
        if (startIndex >= tgtData.length) {
            effPage = 1;
            startIndex = (effPage - 1) * GC_PAGE_SIZE;
        }
        const tgtDataAtPage = tgtData.slice(startIndex, startIndex + GC_PAGE_SIZE);

        rows = tgtDataAtPage.map(pos => {

            return <tr key={pos.posId}>
                {/*<td style={{width: "5%"}}>{pos.accountNbr}</td>*/}
                <td style={{width: "7%"}}><NameTruncWithSearch name={pos.isin} maxSize={250} search={searchString}/>
                </td>
                <td style={{width: "65px"}}>{pos.currency}</td>
                <td style={{width: "120px", textAlign: "right"}}>{pos.valuationFmt}</td>
                <td style={{width: "10%"}}>{pos.gcClassification}</td>
                <td style={{width: "7%"}}>{pos.accountManager}</td>
                <td style={{width: "10%"}}>{pos.broadCategory}</td>
                <td style={{width: "10%"}}>{pos.subCategory}</td>
                <td style={{width: "10%"}}>{pos.detailedCategory1}</td>
                <td style={{width: "10%"}}>{pos.detailedCategory2}</td>
                <td style={{width: "10%"}}><NameTruncWithSearch name={pos.name} maxSize={250} search={searchString}/>
                </td>
                <td style={{width: "10%"}}><NameTruncWithSearch name={pos.displayName} maxSize={250}
                                                                search={searchString}/></td>
                <td><NameTruncWithSearch name={pos.fundName} maxSize={250} search={searchString}/></td>
            </tr>;
        });
    }

    // Search category, the component is common to the global and histo view
    const searchCategory = (e) => {
        let searchString = e.target.value;
        searchCat(searchString);
    }

    const resetSearch = () => {
        searchCat('');
    }

    function searchCat(searchString) {
        setSearchString(searchString);
        if (searchString.trim() === "") {
            setClearSearchVisibility("hidden");
            // Reset the view, show the default categories
            dispatch(resetSearchGC());
        } else {
            if (clearSearchVisibility === "hidden") {
                setClearSearchVisibility("visible");
            }
            dispatch(searchGC({searchString: searchString}));
        }
    }

    let searchCategoryInput = <FormGroup controlId="selectView">
        <input value={searchString}
               placeholder="Search ISIN, name..."
               className={'form-control float-left'}
            // style={{minWidth: "180px"}}
               aria-label="Search category"
               onChange={searchCategory}
               aria-describedby="basic-addon2"/>
    </FormGroup>;

    let searchCategoryComp;
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    if (isIE) {
        searchCategoryComp =
            <div style={{display: "flex", flexWrap: "nowrap", marginRight: 'auto'}}>
                {searchCategoryInput}
            </div>
    } else {
        searchCategoryComp = <div style={{display: "flex", flexWrap: "nowrap"}}>
            {searchCategoryInput}
            <Button variant="light" size="sm"
                    style={{
                        marginLeft: 0,
                        padding: 4,
                        borderWidth: '1px 1px 1px 0',
                        borderColor: '#ced4da',
                        borderRadius: '0.25rem .25rem .25rem 0.25rem',
                        visibility: clearSearchVisibility
                    }}
                    onClick={resetSearch}>
                <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>
                    <TiDeleteOutline/>
                </IconContext.Provider>
            </Button>
            {/*<span style={{paddingLeft: '10px', margin: 'auto'}}>{searchString.length > 0 ? searchCount + ' result(s)' : ''}</span>*/}
        </div>
    }

    const total = tgtDataOrig ? tgtDataOrig.length : 0;

    const pageSelector = <PageSelector page={effPage} pageSize={GC_PAGE_SIZE} size={tgtData.length}
                                       clickFunc={changePage}/>;
    return <>
        <Table hover className={'portfolio sticky-thc'} style={{marginTop: '20px'}}>
            <thead>
            <tr>
                <th className={colorClass + " " + pfBgClass} colSpan={8}>
                    <p className={'canopia2 detail-title-font'}>Global custody
                        ({dateOption.value}, {total} rows)</p>
                </th>
                <th className={colorClass + " " + pfBgClass + " form-padding"} colSpan={4}>
                    <div style={{float: "right"}}>
                        <div style={{display: "flex", flexWrap: "nowrap"}}>
                            <div>{searchCategoryComp}</div>
                            <div style={{margin: '.5rem 0 0 2rem'}}>{pageSelector}</div>
                        </div>
                    </div>
                </th>
            </tr>
            <tr>
                <th className={colorClass + " " + pfBgClass}>
                    <THSort target={targetSort} colName="isin" label="ISIN"/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THSort target={targetSort} colName="currency" label="Ccy"/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THSort target={targetSort} colName="valuation" label="Amount"/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THSort target={targetSort} colName="gcClassification" label="GC Class"/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THSort target={targetSort} colName="accountManager" label="Manager"/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THSort target={targetSort} colName="broadCategory" label="Broad Category"/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THSort target={targetSort} colName="subCategory" label="Sub Category"/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THSort target={targetSort} colName="detailedCategory1" label="Det. Category 1"/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THSort target={targetSort} colName="detailedCategory2" label="Det. Category 2"/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THSort target={targetSort} colName="name" label="Name"/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THSort target={targetSort} colName="displayName" label="Display Name"/>
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    <THSort target={targetSort} colName="fundName" label="Final Name"/>
                </th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </Table>
        <div style={{margin: '.75rem'}}>
            {pageSelector}
        </div>
    </>;
}