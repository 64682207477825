import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {authHeader} from "../services/auth-header";
import {COMPUTING_CONTEXT_URL} from "../containers/canopia/CanopiaUtils";

const API_URL = COMPUTING_CONTEXT_URL + "api/admin/";

const initialState = {
    allClients: [],
    allClientConfigs: [],
    status: "idle",
    error: null,
    updateStatus: "idle",
    updateError: null
}

export const loadAllClients = createAsyncThunk(
    'user/all-clients',
    async () =>
        fetch(API_URL + "all-clients", {headers: authHeader(), cache: 'no-store'})
            .then(response => {
                let contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    console.log("The content type is not JSON, found " + contentType);
                }
            })
            .then(json => json)
            .catch(error => error)
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadAllClients.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(loadAllClients.fulfilled, (state, action) => {
                let payload = action.payload;
                if (payload.status) {
                    state.status = 'error';
                    state.error = payload;
                } else if (payload.message === 'Failed to fetch') {
                    state.status = 'error';
                    state.error = {
                        error: 'Service unavailable', //
                        message: 'We apologize for the inconvenience, our team is working on solving the issue. ' + //
                            'Please try to come back in a few minutes. Thank you for your patience.'
                    };
                } else {
                    state.status = 'success';
                    state.allClients = payload["data"];
                }
            })
            .addCase(loadAllClients.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.payload;
            })
    }
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUserState = state => state.user;

export default userSlice.reducer;