import React, {useRef} from "react";
import {hasCoverage} from "./CanopiaUtils";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import xyaxis from "../../images/consensus-chart-xyaxis.png";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {GoPrimitiveSquare} from "react-icons/go";
import {VscCircleFilled} from "react-icons/vsc";
import Coverage from "./Coverage";
import ConsensusGrade from "./ConsensusGrade";
import {Asterisk} from "react-bootstrap-icons";
import FinalGrade from "./FinalGrade";
import {addAmcharts4Licence} from "./chart/ChartUtils";

export default function Grade(props) {

    const target = useRef(null);

    // const [show, setShow] = useState(false);

    // Grade icon data
    const row = props.row;
    let gradeIcon = "";
    let gradeAdj = 0;
    let createTooltip = false;

    if (row && row.rating && row.rating.trim() !== "") {

        // Final grade
        // let gradeIconPath = getIconPath(row.rating);
        // let alpha = (row.coverage > 0.0 && row.coverage < 0.5) ? 0.5 : 1;
        // gradeIcon = <img alt={row.rating} src={gradeIconPath} width={ICON_SIZE + 4} height={ICON_SIZE + 4} style={{marginRight: 4, opacity: alpha}}/>;
        // gradeIcon = <img alt={row.rating} src={gradeIconPath} width={ICON_SIZE + 4} height={ICON_SIZE + 4}/>;
        gradeIcon = <FinalGrade rating={row.rating} rank={row.ratingRank} size={30}/>;
        gradeAdj = row.ratingRank - row.consRatingRank;
        createTooltip = row.rating !== 'NR' && hasCoverage(row);
    }

    // Tooltip data
    const name = props.name;
    const bmName = props.bmName;
    const pfRow = props.pfRow;
    const bmRow = props.bmRow;
    const showBm = props.showBm;
    const finalGradeIconSize = 50;
    const adjIconSize = '0.8em';
    let pfGradeIconLarge = "";
    let pfConsGradeIconSmall = "";
    let pfGradeAdj = 0;
    let bmGradeIconLarge = "";
    let bmConsGradeIconSmall = "";
    let bmGradeAdj = 0;
    const finalGradeMessage = <p style={{fontSize: "small"}}><Asterisk size={'0.6em'} style={{marginRight: '5px'}}/>Final grade
        adjusted due to the threshold reached by controversies and/or impacts (see methodology for details)</p>;

    if (createTooltip) {
        if (pfRow && pfRow.rating && pfRow.rating.trim() !== "") {

            // Final grade
            pfGradeIconLarge = <FinalGrade rating={pfRow.rating} rank={pfRow.ratingRank} size={finalGradeIconSize}/>;
            // let gradeIconPath = getIconPath(pfRow.rating);
            // pfGradeIconLarge =
            //     <img alt={pfRow.rating} src={gradeIconPath} width={finalGradeIconSize} height={finalGradeIconSize}/>;

            // Consensus grade
            if (pfRow.consensusRating && pfRow.consensusRating.trim() !== "") {
                pfConsGradeIconSmall = <ConsensusGrade rating={pfRow.consensusRating} rank={pfRow.consRatingRank}/>

                pfGradeAdj = pfRow.ratingRank - pfRow.consRatingRank;
            }
        }
        if (showBm && bmRow && bmRow.rating && bmRow.rating.trim() !== "") {

            // Final grade
            // let bmGradeIconPath = getIconPath(bmRow.rating);
            // bmGradeIconLarge =
            //     <img alt={bmRow.rating} src={bmGradeIconPath} width={finalGradeIconSize} height={finalGradeIconSize}/>;
            bmGradeIconLarge = <FinalGrade rating={bmRow.rating} rank={bmRow.ratingRank} size={finalGradeIconSize}/>;

            // Consensus grade
            if (bmRow.consensusRating && bmRow.consensusRating.trim() !== "") {
                bmConsGradeIconSmall = <ConsensusGrade rating={bmRow.consensusRating} rank={bmRow.consRatingRank}/>

                bmGradeAdj = bmRow.ratingRank - bmRow.consRatingRank;
            }
        }
    }

    let charts = [];

    function loadChart() {
        /* Chart code */
        am4core.useTheme(am4themes_animated);

        if (showBm) {
            createXYChart(pfRow, bmRow, "chartdiv");
            // createXYChart(pfRow, bmRow, "chartdiv-xy");
        } else {
            createPieChart("chartdiv", pfRow);
            // createPieChart("chartdiv-pf-pie", pfRow);
            // createPieChart("chartdiv-bm-pie", bmRow);
        }

        // AMCHARTS 5
        // let root = am5.Root.new("chartdiv");
        // let consensusPieChart = root.container.children.push(
        //     am5percent.PieChart.new(root, {
        //         radius: am5.percent(70),
        //         innerRadius: am5.percent(35),
        //         angle: 15,
        //         depth: 15,
        //         startAngle: -90,
        //         endAngle: 270,
        //         marginTop: 30,
        //         marginBottom: 30
        //     })
        // );
        //
        // // DOES NOT WORK
        // consensusPieChart.get("colors", null).set("colors", [
        //     am5.color("#1DB964"),
        //     am5.color("#92D050"),
        //     am5.color("#C5E0B4"),
        //     am5.color("#F8ADAF"),
        //     am5.color("#F48588"),
        //     am5.color("#C61216")
        // ]);
        // let series = consensusPieChart.series.push(
        //     am5percent.PieSeries.new(root, {
        //         name: "Consensus",
        //         categoryField: "esgDist",
        //         valueField: "weight"
        //     })
        // );
        // series.data.setAll([
        //     {
        //         esgDist: "Above Strong",
        //         weight: esgReport.wgtAboveS,
        //     },
        //     {
        //         esgDist: "Above Medium",
        //         weight: esgReport.wgtAboveM,
        //     },
        //     {
        //         esgDist: "Above Weak",
        //         weight: esgReport.wgtAboveW,
        //     },
        //     {
        //         esgDist: "Below Weak",
        //         weight: esgReport.wgtBelowW,
        //     },
        //     {
        //         esgDist: "Below Medium",
        //         weight: esgReport.wgtBelowM,
        //     },
        //     {
        //         esgDist: "Below Strong",
        //         weight: esgReport.wgtBelowS,
        //     }
        // ]);
        //
        // let legend = consensusPieChart.children.push(am5.Legend.new(root, {
        //     centerX: am5.percent(50),
        //     x: am5.percent(50),
        //     layout: root.horizontalLayout
        // }));
        //
        // legend.data.setAll(series.dataItems);
    }

    function createPieChart(divElem, row) {
        if (!row) {
            return;
        }

        addAmcharts4Licence();
        let chart = am4core.create(divElem, am4charts.PieChart);
        charts.push(chart);
        // let consensusPieChart = am4core.create("chartdiv", am4charts.PieChart);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.legend = new am4charts.Legend();
        let legend = chart.legend;
        legend.position = 'right';
        legend.valign = 'top';
        legend.maxHeight = 150;
        legend.marginTop = 18;
        legend.itemContainers.template.paddingTop = 2;
        legend.itemContainers.template.paddingBottom = 2;
        let markerTemplate = legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;

        chart.data = [
            {
                esgDist: "Above Strong",
                weight: row.wgtAboveS,
                color: am4core.color("#5DCA8B")
            },
            {
                esgDist: "Above Medium",
                weight: row.wgtAboveM,
                color: am4core.color("#86D7A8")
            },
            {
                esgDist: "Above Weak",
                weight: row.wgtAboveW,
                color: am4core.color("#AEE5C5")
            },
            {
                esgDist: "Below Weak",
                weight: row.wgtBelowW,
                color: am4core.color("#F8BFC2")
            },
            {
                esgDist: "Below Medium",
                weight: row.wgtBelowM,
                color: am4core.color("#F17F86")
            },
            {
                esgDist: "Below Strong",
                weight: row.wgtBelowS,
                color: am4core.color("#E72A35")
            }
        ];

        chart.innerRadius = 35;
        chart.angle = 15;
        chart.depth = 15;
        chart.startAngle = -90;
        chart.endAngle = 270;
        chart.radius = am4core.percent(70);
        chart.marginTop = 30;
        chart.marginBottom = 30;

        // let label = chart.seriesContainer.createChild(am4core.Label);
        // label.text = gradeIcon;
        // label.html = "{gradeIcon}";
        // label.horizontalCenter = "middle";
        // label.verticalCenter = "middle";

        // let series = chart.series.push(new am4charts.PieSeries3D());
        let series = chart.series.push(new am4charts.PieSeries());
        // series.slices.template.stroke = am4core.color("#ffffff");
        // series.slices.template.strokeWidth = 2;
        // series.slices.template.strokeOpacity = 0;
        series.slices.template.propertyFields.fill = "color";
        series.slices.template.stroke = am4core.color("#fff");
        series.slices.template.strokeWidth = 2;
        series.slices.template.strokeOpacity = 1;
        series.dataFields.value = "weight";
        series.dataFields.category = "esgDist";
        // Disable labels
        series.ticks.template.disabled = true;
        series.labels.template.text = "";
        // series.alignLabels = false;
        // series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        // series.labels.template.radius = am4core.percent(-40); // to change the distance of the labels from the center
        // series.labels.template.fill = am4core.color("black");
    }

    function createXYChart(pfRow, bmRow, divElem) {
        addAmcharts4Licence();
        let chart = am4core.create(divElem, am4charts.XYChart);
        charts.push(chart);

        // chart.legend = new am4charts.Legend();
        // let legend = chart.legend;
        // legend.position = 'bottom';
        // legend.layout = 'vertical';
        // legend.height = 25;
        // //legend.marginLeft = 20;
        // legend.marginTop = 0;
        // legend.marginBottom = 0;
        // legend.itemContainers.template.paddingTop = 2;
        // legend.itemContainers.template.paddingBottom = 2;
        // let markerTemplate = legend.markers.template;
        // markerTemplate.width = 10;
        // markerTemplate.height = 10;

        chart.colors.list = [
            am4core.color("#1D416D"),
            am4core.color("#53565a")
        ];

        // PDFs setup
        const averageMin = 0.45;
        const averageMax = 1.0;
        const strengthMin = -0.15;
        const strengthMax = 0.45;

        // Add x axis
        let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        // Give some margin if the point is at the max/min
        xAxis.min = averageMin - 0.05;
        xAxis.max = averageMax + 0.05;
        // xAxis.min = averageMin;
        // xAxis.max = averageMax;
        xAxis.strictMinMax = true;
        xAxis.title.text = "Consensus average";
        xAxis.title.dy = -25;
        // Hide the axis, grid and labels
        // xAxis.opacity = 0;
        xAxis.renderer.grid.template.opacity = 0;
        xAxis.renderer.baseGrid.disabled = true;
        xAxis.renderer.labels.template.opacity = 0;
        xAxis.renderer.minGridDistance = 10;

        // Add y axis
        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = strengthMin - 0.05;
        yAxis.max = strengthMax + 0.05;
        // yAxis.min = strengthMin;
        // yAxis.max = strengthMax;
        yAxis.strictMinMax = true;
        yAxis.title.text = "Consensus strength";
        yAxis.title.dx = 30;
        // Hide the axis, grid and labels
        // yAxis.opacity = 0;
        yAxis.renderer.grid.template.opacity = 0;
        yAxis.renderer.baseGrid.disabled = true;
        yAxis.renderer.labels.template.opacity = 0;
        yAxis.renderer.minGridDistance = 10;

        // Add Data
        const pfAverage = Math.max(Math.min(pfRow.consensusWgt, averageMax), averageMin);
        const pfStrength = Math.max(Math.min(pfRow.strengthWgt, strengthMax), strengthMin);
        const bmAverage = bmRow ? Math.max(Math.min(bmRow.consensusWgt, averageMax), averageMin) : null;
        const bmStrength = bmRow ? Math.max(Math.min(bmRow.strengthWgt, strengthMax), strengthMin) : null;
        chart.data = [{
            pfAverage: pfAverage,
            pfStrength: pfStrength,
            bmAverage: bmAverage,
            bmStrength: bmStrength
        }];

        // Add series
        let pfSeries = chart.series.push(new am4charts.LineSeries());
        pfSeries.name = name;
        pfSeries.dataFields.valueX = "pfAverage";
        pfSeries.dataFields.valueY = "pfStrength";

        let pfBullet = pfSeries.bullets.push(new am4charts.CircleBullet());
        pfBullet.circle.radius = 3;
        pfBullet.circle.fill = am4core.color("#1D416D");

        if (bmRow) {
            let bmSeries = chart.series.push(new am4charts.LineSeries());
            bmSeries.name = bmName;
            bmSeries.dataFields.valueX = "bmAverage";
            bmSeries.dataFields.valueY = "bmStrength";

            // let bmBullet = bmSeries.bullets.push(new am4charts.CircleBullet());
            // bmBullet.circle.radius = 3;
            // bmBullet.circle.fill = am4core.color("#53565a");
            let bmBullet = bmSeries.bullets.push(new am4charts.Bullet());
            let square = bmBullet.createChild(am4core.Rectangle);
            square.width = 6;
            square.height = 6;
            square.horizontalCenter = "middle";
            square.verticalCenter = "middle";
        }

        // image
        let axisImage = new am4core.Image();
        axisImage.href = xyaxis;
        chart.plotContainer.children.push(axisImage);
        axisImage.horizontalCenter = "middle";
        axisImage.verticalCenter = "middle";
        axisImage.opacity = 1;
        axisImage.height = 239;
        axisImage.width = 350;
        axisImage.dx = 125;
        axisImage.dy = 85;
    }

    function removeChart() {
        charts.forEach(chart => {
            chart.dispose();
        });
    }

    // function showModal() {
    //     setShow(true);
    // }
    //
    // function hideModal() {
    //     setShow(false);
    //     removeChart();
    // }

    if (createTooltip) {

        return <OverlayTrigger
            placement='auto'
            delay={{show: 100, hide: 0}}
            trigger={["hover", "focus"]}
            target={target}
            onEntered={loadChart}
            onToggle={removeChart}
            overlay={
                <Tooltip id="tooltip-bottom">
                    <div>
                        {showBm ? <>
                            <div>
                                <h4 className={'canopia'}>Final Grade</h4>
                            </div>
                            <div style={{
                                fontWeight: 'bold',
                                marginTop: '15px',
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr"
                            }}>
                                <div className={"canopia2"}>{name}</div>
                                <div className={"canopia3"} style={{marginLeft: '15px'}}>{bmName}</div>
                            </div>
                            <div style={{marginTop: '10px', display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                                <div style={{display: "flex", flexWrap: "nowrap", marginLeft: '10px'}}>
                                    {pfGradeIconLarge}
                                    {pfGradeAdj !== 0 && <Asterisk size={adjIconSize}/>}
                                </div>
                                <div style={{display: "flex", flexWrap: "nowrap", marginLeft: '25px'}}>
                                    {bmGradeIconLarge}
                                    {bmGradeAdj !== 0 && <Asterisk size={adjIconSize}/>}
                                </div>
                            </div>
                            <div style={{marginTop: '15px', width: '100%'}}>
                                {(pfGradeAdj !== 0 || bmGradeAdj !== 0) &&
                                    finalGradeMessage}
                            </div>
                            <div style={{marginTop: '25px'}}>
                                <h4 className={'canopia'}>ESG Consensus Grade</h4>
                            </div>
                            <div style={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                marginTop: '15px',
                                marginBottom: '15px',
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr"
                            }}>
                                <div className={"canopia2"} style={{paddingRight: '10px'}}>
                                    <VscCircleFilled/>Portfolio {pfConsGradeIconSmall}</div>
                                <div className={"canopia3"}>{bmName && <>
                                    <GoPrimitiveSquare/>Benchmark {bmConsGradeIconSmall}</>}</div>
                            </div>
                            <div id="chartdiv" className="chart-consensus-xy"/>
                        </> : <>
                            <h3 className={'canopia2'}>{name}</h3>
                            <div style={{marginTop: '10px'}}>
                                <h4 className={'canopia'}>Final Grade</h4>
                            </div>
                            <div style={{marginTop: '15px', display: "grid", gridTemplateColumns: "1fr 2fr"}}>
                                <div style={{display: 'flex', marginLeft: '10px'}}>
                                    {pfGradeIconLarge}
                                    {pfGradeAdj !== 0 && <Asterisk size={adjIconSize}/>}
                                </div>
                                <div style={{
                                    margin: '0 10px',
                                    width: '200px'
                                }}>{pfGradeAdj !== 0 && finalGradeMessage}</div>
                            </div>
                            <div style={{marginTop: '25px'}}>
                                <h4 className={'canopia'}>ESG Consensus Grade</h4>
                            </div>
                            <div style={{
                                marginTop: '15px',
                                textAlign: 'center',
                                display: "grid",
                                gridTemplateColumns: "165px 1fr"
                            }}>
                                <div>{pfConsGradeIconSmall}</div>
                                <div style={{display: "grid", gridTemplateColumns: "15px 1fr", marginLeft: "18px"}}>
                                    <div style={{marginTop: "10px"}}><Coverage esgReport={pfRow}/></div>
                                    <div style={{marginTop: "6px", textAlign: 'left'}}>Coverage<span
                                        style={{marginLeft: "40px"}}></span>{pfRow.covFmt}</div>
                                </div>
                            </div>
                            <div style={{textAlign: 'left'}}>
                                {/*<ConsensusColumnChart esgReport={row} width={'95%'}/>*/}
                                <div id="chartdiv" className="chart-rating"/>
                            </div>
                        </>}
                    </div>
                </Tooltip>
            }
            defaultShow={false}>
            <span style={{display: "flex", flexWrap: "nowrap", marginLeft: "20px"}}>
                <div ref={target}>{gradeIcon}</div>
                {gradeAdj !== 0 && <Asterisk size={'0.5em'}/>}
            </span>
        </OverlayTrigger>;

        // return <>
        //     <span style={{display: 'inline-block'}} className={'label-hover'} onClick={() => showModal()}>
        //          <div ref={target}>{gradeIcon}</div>
        //      </span>
        //     <Modal show={show} onEntered={() => loadChart()} fullscreen={true} onHide={() => hideModal()} scrollable={true}>
        //         <Modal.Header closeButton>
        //             <Modal.Title><h3 className={'canopia2'}>{category}</h3></Modal.Title>
        //         </Modal.Header>
        //         <Modal.Body>
        //             <div className={'canopia-modal-content-fullscreen'}>
        //                 <div id="chartdiv-xy" className="chart-consensus-xy"/>
        //                 <img alt="ESG Consensus" src={esgConsensus} width={126} height={16} style={{marginLeft: '45px', marginTop: '30px', marginBottom: '15px'}}/>
        //                 {category}
        //                 <div id="chartdiv-pf-pie" className="chart-rating"/>
        //                 Benchmark
        //                 <div id="chartdiv-bm-pie" className="chart-rating"/>
        //             </div>
        //         </Modal.Body>
        //     </Modal>
        // </>
    } else {
        return <div style={{display: "flex", flexWrap: "nowrap", marginLeft: "20px"}} ref={target}>{gradeIcon}</div>
    }
}
