import React, {useEffect, useState} from "react";
import Table from "react-bootstrap/Table";
import {useDispatch, useSelector} from 'react-redux';
import {changeSelectedAmountField, selectPortfolioState} from '../../reducer/portfolioSlice';
import {clearAllFundsFilters, filterFunds, selectFundsState, setFunds} from "../../reducer/fundsSlice";
import THSort from "./THSort";
import {AMOUNT_CONTROLS, getAmountControlsForFunds, getThemes, THEMES, VIEW_MODES} from "./CanopiaUtils";
import ESGMetricsHeaders from "./ESGMetricsHeaders";
import FundRow from "./FundRow";
import FlatSelect from "./FlatSelect";
import {selectClientState} from "../../reducer/clientSlice";
import THFilter from "./THFilter";
import FilterResult from "./FilterResult";
import {PageSelector} from "../core/PageSelector";

export default function Funds(props) {

    const PAGE_SIZE = 50;

    // const searchCategoryInput = props.searchInput;
    const dateSelect = props.dateSelect;

    const [theme, setTheme] = useState(THEMES[0]);
    const [subTheme, setSubTheme] = useState(THEMES[0].subThemes[0]);
    // pagination
    const [page, setPage] = useState(1);

    const dispatch = useDispatch();

    const {clientConfig} = useSelector(selectClientState);
    const {pfData, date, selectedAmountField} = useSelector(selectPortfolioState);
    const {tgtDataOrig, tgtData} = useSelector(selectFundsState);

    useEffect(() => {
        if (pfData) {
            dispatch(setFunds({pfData, date}));
        }
    }, [pfData, date, dispatch]);

    // if (!pfData || !pfData.fundsReports || pfData.fundsReports.length === 0) {
    //     return <div>No data found</div>;
    // }

    function changeTheme(theme) {
        setTheme(theme);
        changeSubTheme(theme.subThemes[0]);
    }

    function changeSubTheme(theme) {
        setSubTheme(theme);
    }

    let pfBgClass = 'background-light';
    let bmBgClass = 'background-light';

    const themes = getThemes(clientConfig);
    const amountFields = getAmountControlsForFunds(clientConfig.showAmounts, clientConfig.clientWrapper.currency);

    // Relative % is not relevant in Funds tab
    const adjAmountField = selectedAmountField.key === 'relPct' ? AMOUNT_CONTROLS[1] : selectedAmountField;

    const targetSort = "funds";

    let esgThemesH1 =
        <th className={"canopia2 background-light canopia-border-left"} colSpan={theme.cols * 2 + 1}>
            <FlatSelect enums={themes} curValue={theme} curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
                        clickFunction={changeTheme} clickParamFromEnum={true}/>
        </th>

    const viewMode = VIEW_MODES[1];

    let esgThemesH2 = <ESGMetricsHeaders
        theme={theme}
        themes={themes}
        subTheme={subTheme}
        changeSubThemeFunc={changeSubTheme}
        showBm={true}
        sort={true}
        target={targetSort}
        borderLeftClassName={'canopia-border-left'}
        bgClass={pfBgClass}
        viewModeKey={viewMode.key}/>

    // Amount data
    function changeAmountField(field) {
        dispatch(changeSelectedAmountField({amountField: field}));
    }

    function changePage(page) {
        setPage(page.key);
    }

    let amountControlDiv = amountFields.length > 1 ?
        <FlatSelect enums={amountFields} curValue={adjAmountField} curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
                    clickFunction={changeAmountField} clickParamFromEnum={true}/>
        : amountFields[0].label;

    let colorClass = 'canopia2';
    let bmColorClass = 'canopia2';

    let rows;
    let displayed = 0;
    let effPage = page;
    let message;
    let pageSelector = "";
    if (tgtData && tgtData.length > 0) {

        let startIndex = (effPage - 1) * PAGE_SIZE;
        if (startIndex >= tgtData.length) {
            effPage = 1;
            startIndex = (effPage - 1) * PAGE_SIZE;
        }
        const tgtDataAtPage = tgtData.slice(startIndex, startIndex + PAGE_SIZE);

        displayed = tgtData.length;

        let rowId = 0;
        rows = tgtDataAtPage.map(pfRow => {
            return <FundRow key={rowId++} row={pfRow} theme={theme} subTheme={subTheme}/>
        });

        pageSelector = <PageSelector page={effPage} pageSize={PAGE_SIZE} size={tgtData.length}
                                                     clickFunc={changePage}/>;
    } else {
        message = <div style={{width: '100%', padding: '10px 0 0 10px'}}><b>No data found</b></div>;
    }
    const total = tgtDataOrig ? tgtDataOrig.length : 0;

    const showClearAllFilters = displayed !== total;

    function clearAllFilters() {
        dispatch(clearAllFundsFilters());
    }

    return (
        <div>
            <Table hover className={'portfolio sticky-thc'}>
                <thead>
                <tr>
                    <th className={colorClass + " " + pfBgClass} width={'100px'}>
                        Holdings
                    </th>
                    <th className={colorClass + " " + pfBgClass + " form-padding"} width={'5%'}>
                        <span style={{float: 'left'}}>{dateSelect}</span>
                    </th>
                    <th className={colorClass + " " + pfBgClass} colSpan={2}>
                    </th>
                    <th className={colorClass + " " + pfBgClass} colSpan={2}>
                        <div style={{display: "flex", flexWrap: "nowrap"}}>
                            {pageSelector}
                        </div>
                    </th>
                    <th className={colorClass + " " + pfBgClass} width={'35%'} colSpan={3}>
                        <FilterResult showClearButton={showClearAllFilters}
                                      clearFunc={clearAllFilters}
                                      displayed={displayed}
                                      total={total}>
                        </FilterResult>
                    </th>
                    {esgThemesH1}
                </tr>
                <tr>
                    <th className={colorClass + " " + pfBgClass} colSpan={2}>
                        <THSort target={targetSort} colName="category" label="Name"/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}/>
                    {/*<th className={colorClass + " " + pfBgClass} style={{minWidth: "70px"}}>*/}
                    {/*    <THSort colName="isin" label="Isin"/>*/}
                    {/*</th>*/}
                    <th className={colorClass + " " + pfBgClass} style={{minWidth: '65px'}}>
                        <THFilter targetSort={targetSort} sortCol="currency" filterCol="currency" label="Ccy" showLegend={false} selectFunc={selectFundsState}
                                  filterFunc={filterFunds}/>
                    </th>
                    {/*<th className={colorClass + " " + pfBgClass} style={{minWidth: '70px'}}>*/}
                    {/*    <THSort colName="structure" label="Type"/>*/}
                    {/*</th>*/}
                    <th className={colorClass + " " + pfBgClass}>
                        <THFilter targetSort={targetSort} sortCol="lipperGlobalScheme" filterCol="lipperGlobalScheme" label="Asset Class" showLegend={false} selectFunc={selectFundsState}
                                  filterFunc={filterFunds}/>
                    </th>
                    {/*<th className={colorClass + " " + pfBgClass} style={{minWidth: "100px"}}>*/}
                    {/*    <THSort colName="account" label="Account"/>*/}
                    {/*</th>*/}
                    <th className={colorClass + " " + pfBgClass} style={{minWidth: '95px'}}>
                        <THFilter targetSort={targetSort} sortCol="manager" filterCol="manager" label="Manager" showLegend={false} selectFunc={selectFundsState}
                                  filterFunc={filterFunds}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THSort target={targetSort} colName="lookthroughFormatted" label="Lookthrough"/>
                    </th>
                    <th className={colorClass + " " + pfBgClass} style={{minWidth: '66px'}}>
                        <THSort target={targetSort} colName="esgReport.nbPos" label="Pos."/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        {/*<THSort colName="amount" label="Amount"/>*/}
                        <THSort target={targetSort} colName="weight" label={amountControlDiv}/>
                    </th>
                    {/*<th className={colorClass + " " + pfBgClass} style={{minWidth: "100px"}}>*/}
                    {/*    <THSort colName="coverage" label="Coverage"/>*/}
                    {/*</th>*/}
                    {esgThemesH2}
                    <ESGMetricsHeaders
                        theme={theme}
                        themes={themes}
                        subTheme={subTheme}
                        borderLeftClassName={'border-large-left'}
                        bgClass={bmBgClass}
                        type={'bm'}
                        viewModeKey={viewMode.key}/>
                    <th className={bmColorClass + " " + bmBgClass + " canopia-border-left"}>Benchmark</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Table>
            {message}
        </div>
    );
}
