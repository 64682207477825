import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {authHeader} from "../services/auth-header";
import {
    COMPUTING_CONTEXT_URL,
    filter,
    getNextSort,
    parseNull,
    sort
} from "../containers/canopia/CanopiaUtils";

const API_URL = COMPUTING_CONTEXT_URL + "api/admin/";

const SEARCH_PROPS = ['fundName', 'isin', 'displayName', 'name'];

const gcInitialSortStatus = {
    "posId": "desc",
    "accountManager": "asc",
    "isin": "asc",
    "currency": "asc",
    "valuation": "asc",
    "gcClassification": "asc",
    "broadCategory": "asc",
    "subCategory": "asc",
    "detailedCategory1": "asc",
    "detailedCategory2": "asc",
    "category2": "asc",
    "displayName": "asc",
    "fundName": "asc"
}

const sortAndFilterInitialState = {
    sortStatus: gcInitialSortStatus,
    sortCol: "posId",
    filterValuesOrig: {}, // all the values of the filters corresponding to tgtDataOrig (initialized once, immutable then)
    filterValues: {}, // all the values of the filters
    filterSelectedValues: {}, // the current selected values of the filters
}

const initialState = {
    clientConfig: null,

    // GC
    tgtDataOrig: null,
    tgtData: [], // The displayed ones, filtered, sorted, ...
    gcStatus: "idle",
    gcError: null,

    benchmarks: null,
    bmStatus: "idle",
    bmError: null,

    saveStatus: "idle",
    saveMessages: null,

    searchString: '',
    searchCount: null,

    ...sortAndFilterInitialState
}

const filterCols = { //
    // 'accountNbr': 0, // 0 => the filter is inactive, 1 => 1st active filter, ...
    'accountManager': 0, //
    'gcClassification': 0, //
    'broadCategory': 0, //
    'subCategory': 0, //
    'detailedCategory1': 0, //
    'detailedCategory2': 0 //
};

function resetFilters(state) {
    state.filterValues = state.filterValuesOrig;
    state.filterSelectedValues = state.filterValuesOrig;
    Object.keys(filterCols).forEach(filterCol => {
        filterCols[filterCol] = 0;
    });
}

export const loadGC = createAsyncThunk(
    'setup/gc',
    async (params) =>
        fetch(API_URL + "gc?clientId=" + params.clientId + "&dateStr=" + params.dateStr, {
            headers: authHeader(),
            cache: 'no-store'
        })
            .then(response => {
                let contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    console.log("The content type is not JSON, found " + contentType);
                }
            })
            .then(json => json)
            .catch(error => error)
);

export const saveSetup = createAsyncThunk(
    'setup/save',
    async (params) => {
        let headers = authHeader();
        headers['Content-Type'] = 'application/json';

        fetch(API_URL + "setup/save",
            {headers: headers, method: "POST", cache: 'no-store', body: JSON.stringify(params)})
            .then(response => {
                let contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    console.log("The content type is not JSON, found " + contentType);
                }
            })
            .then(json => json)
            .catch(error => error)
    }
);

export const setupSlice = createSlice({
    name: 'setup',
    initialState,
    reducers: {
        sortGC: (state, action) => {
            // load the portfolio of the selected client
            let col = action.payload.col;

            sort(state, col, gcInitialSortStatus);
        },
        filterGC: (state, action) => {
            // Update the filters with the new selection
            const colName = action.payload.colName;
            const selectedValues = action.payload.selectedValues;

            filter(state, colName, filterCols, selectedValues, gcInitialSortStatus);
        },
        clearAllGCFilters: (state, action) => {
            state.tgtData = [...state.tgtDataOrig];

            resetFilters(state);

            let prevSort = state.sortStatus[state.sortCol];
            state.sortStatus[state.sortCol] = getNextSort(prevSort);
            sort(state, state.sortCol, gcInitialSortStatus);
        },
        searchGC: (state, action) => {
            state.searchString = action.payload.searchString;

            search(state);
        },
        resetSearchGC: (state, action) => {
            state.searchString = '';
            state.tgtData = [...state.tgtDataOrig];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadGC.pending, (state, action) => {
                state.gcStatus = 'loading';
            })
            .addCase(loadGC.fulfilled, (state, action) => {
                let payload = action.payload;
                if (payload.status) {
                    state.gcStatus = 'error';
                    state.gcError = payload;
                } else if (payload.message === 'Failed to fetch') {
                    state.gcStatus = 'error';
                    state.gcError = {
                        error: 'Service unavailable', //
                        message: 'We apologize for the inconvenience, our team is working on solving the issue. ' + //
                            'Please try to come back in a few minutes. Thank you for your patience.'
                    };
                } else {
                    state.gcStatus = 'success';
                    const pair = payload["data"];
                    state.clientConfig = pair.key;
                    state.tgtDataOrig = pair.value;
                    state.tgtData = [...state.tgtDataOrig];

                    // Reset the sort and filter
                    state = Object.assign(state, sortAndFilterInitialState);

                    if (state.tgtDataOrig) {
                        const tmpValues = {};
                        Object.keys(filterCols).forEach(filterCol => {
                            tmpValues[filterCol] = [];
                        });
                        state.tgtDataOrig.forEach(dLine => {
                            Object.keys(filterCols).forEach(filterCol => {
                                const value = parseNull(dLine[filterCol]);
                                if (!tmpValues[filterCol].includes(value)) {
                                    tmpValues[filterCol].push(value);
                                }
                            });
                        });
                        Object.keys(tmpValues).forEach(filterCol => {
                            tmpValues[filterCol].sort();
                        });
                        state.filterValuesOrig = tmpValues;

                        resetFilters(state);

                        sort(state, state.sortCol, gcInitialSortStatus);

                    }
                }
            })
            .addCase(loadGC.rejected, (state, action) => {
                state.gcStatus = 'error';
                state.gcError = action.payload;
            })
            .addCase(saveSetup.pending, (state, action) => {
                state.updateStatus = 'loading';
            })
            .addCase(saveSetup.fulfilled, (state, action) => {
                let payload = action.payload;
                if (payload.status) {
                    state.saveStatus = 'error';
                    state.saveError = payload;
                } else if (payload.message === 'Failed to fetch') {
                    state.saveStatus = 'error';
                    state.saveError = {
                        error: 'Service unavailable', //
                        message: 'We apologize for the inconvenience, our team is working on solving the issue. ' + //
                            'Please try to come back in a few minutes. Thank you for your patience.'
                    };
                } else {
                    state.saveStatus = 'success';
                    state.saveMessages = payload.data ? payload.data : null
                }
            })
            .addCase(saveSetup.rejected, (state, action) => {
                state.saveStatus = 'error';
                state.saveError = action.payload;
            })
    }
});

function match(pos, props, searchString) {
    return props.some(prop => pos[prop] != null && pos[prop].toLowerCase().includes(searchString.toLowerCase()))
}

function search(state) {
    state.searchCount = 0;
    let searchResult = [];
    state.tgtDataOrig.forEach(pos => {
        if (match(pos, SEARCH_PROPS, state.searchString)) {
            state.searchCount++;
            searchResult.push(pos);
        }
    });
    state.tgtData = searchResult;
}

export const {
    sortGC,
    filterGC,
    clearAllGCFilters,
    searchGC,
    resetSearchGC
} = setupSlice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectSetupState = state => state.setup;

export default setupSlice.reducer;