import React from "react";
import SimpleToolTip from "../SimpleToolTip";
import {CheckCircleFill, ExclamationCircleFill} from "react-bootstrap-icons";
import ConsensusGrade from "../ConsensusGrade";

export default function GradeTarget(props) {

    const pfName = props.pfName;
    const pfRow = props.pfRow;

    const pfConsensusRatingRank = pfRow.consRatingRank;

    // TODO get from param
    const tgtConsensusRatingRank = 6;
    const tgtConsensusRating = 'B-';

    let gradeInd = pfConsensusRatingRank > 0 && (pfConsensusRatingRank < tgtConsensusRatingRank ?
        <ExclamationCircleFill size={'1.5em'} className={'suitability-warning'}/> :
        // <BsCheckLg size={'1.5em'} className={'suitability-ok'}/>);
        <CheckCircleFill size={'1.5em'} className={'suitability-ok'}/>);

    // Consensus grade
    let pfConsGradeIcon = "";
    if (pfRow.consensusRating && pfRow.consensusRating.trim() !== "") {
        pfConsGradeIcon = <ConsensusGrade rating={pfRow.consensusRating} rank={pfConsensusRatingRank}/>
    }

    let tgtConsGradeIcon = "";
    if (tgtConsensusRatingRank) {
        tgtConsGradeIcon = <ConsensusGrade rating={tgtConsensusRating} rank={tgtConsensusRatingRank}/>
    }

    let gradeContent = <>
        <h3 className={'canopia2'}>{pfName}</h3>
        <p className={'canopia'}><b>ESG Consensus grade</b></p>
        <div style={{
            textAlign: 'center',
            fontWeight: 'bold',
            marginTop: '15px',
            display: "grid",
            gridTemplateColumns: "1fr 1fr"
        }}>
            <div className={"canopia2"} style={{paddingRight: '10px'}}>Actual</div>
            <div className={"canopia3"}>Target</div>
        </div>
        <div style={{textAlign: 'center', marginTop: '5px', display: "grid", gridTemplateColumns: "1fr 1fr"}}>
            <div>
                {pfConsGradeIcon}
            </div>
            <div>
                {tgtConsGradeIcon}
            </div>
        </div>
    </>;

    return <SimpleToolTip content1={gradeInd} content2={gradeContent}/>;
}
