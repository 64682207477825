import React, {useState} from "react";
import {ProgressBar} from "react-bootstrap";
import {COMPUTING_CONTEXT_URL} from "../canopia/CanopiaUtils";
import {authHeader} from "../../services/auth-header";
import {StompSessionProvider, useStompClient, useSubscription} from "react-stomp-hooks";
import Run from "./Run";
import {CheckCircle, XCircle} from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LabelWithTooltip from "../canopia/LabelWithTooltip";

const emptyQueueMsg = 'The job queue is empty';
const padding = '.5rem';

export default function JobQueue(props) {

    return (
        <div className="main-content">
            <div className={'setup-box'}>
                <Run/>
                <StompSessionProvider
                    //All options supported by @stomp/stompjs can be used here
                    connectHeaders={authHeader()}
                    reconnectDelay={5000}
                    url={COMPUTING_CONTEXT_URL + 'api/ws'}
                    debug={(str) => {
                        console.log(str);
                    }}
                >
                    <div style={{
                        width: '45rem', margin: '2rem 0 0 5rem'
                    }}>
                        <div key={-2} className={'canopia2 background-light'} style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            flexGrow: "1",
                            padding: padding,
                            borderTop: "1px solid #bbc6d3"
                        }}>
                            <div style={{width: "50px", padding: padding}}>
                                <LabelWithTooltip label={'Status'} text={'Click on the status icon to see the logs'}/>
                            </div>
                            <div style={{width: "50px", padding: padding}}>
                                Job Id
                            </div>
                            <div style={{width: "120px", padding: padding}}>
                                Start time
                            </div>
                            <div style={{width: "90px", padding: padding}}>
                                User
                            </div>
                            <div style={{width: "70px", padding: padding}}>
                                Client Id
                            </div>
                            <div style={{width: "80px", padding: padding}}>
                                Pf date
                            </div>
                            <div style={{width: "70px", padding: padding}}>
                                Consensus
                            </div>
                        </div>
                        <Subscription/>
                    </div>
                    <SendAfterSubscription/>
                </StompSessionProvider>
            </div>
        </div>
    );
}

export function Subscription() {
    // Job status
    const [runningJobs, setRunningJobs] = useState([]);
    const [jobsMessage, setJobsMessage] = useState('Connecting to the job queue... try to refresh the page if not successful');
    const [showModal, setShowModal] = useState(false);
    const [logs, setLogs] = useState(null);

    useSubscription('/api/admin/jobs-status', (msg) => {
        const jobs = JSON.parse(msg.body);
        console.log("Message received", jobs);

        const newJobs = [...jobs];
        newJobs.sort((a, b) => b.jobId - a.jobId);

        setRunningJobs(newJobs);
        if (newJobs.length === 0) {
            setJobsMessage(emptyQueueMsg);
        }
    });

    function showLogModal(logs) {
        setLogs(logs);
        setShowModal(true);
    }

    function hideModal() {
        setLogs(null);
        setShowModal(false);
    }

    return <div>
        {runningJobs.length > 0 ? runningJobs.map(job => {
            return <div key={job.jobId} style={{
                display: "flex",
                flexWrap: "nowrap",
                flexGrow: "1",
                margin: "0",
                padding: padding,
                borderTop: "1px solid #bbc6d3"
            }}>
                <div style={{width: "50px", padding: padding}}>
                    {/*{job.status === 'WAITING' && <ProgressBar animated now={100}/>}*/}
                    {job.status === 'RUNNING' && <ProgressBar animated now={100}/>}
                    {job.status === 'SUCCESS' &&
                        <span className={'label-hover'} onClick={() => showLogModal(job.logs)}>
                            <CheckCircle color={"var(--canopia-color-coverage-high)"}/>
                        </span>}
                    {job.status === 'FAILED' &&
                        <span className={'label-hover'} onClick={() => showLogModal(job.logs)}>
                            <XCircle color={"var(--canopia-color-climate-neg)"}/>
                        </span>}
                </div>
                <div style={{width: "50px", padding: padding}}>{job.jobId}</div>
                <div style={{width: "120px", padding: padding}}>{job.startTime}</div>
                <div style={{width: "90px", padding: padding}}>{job.firstname + ' ' + job.lastName}</div>
                <div style={{width: "70px", padding: padding}}>{job.clientId}</div>
                <div style={{width: "80px", padding: padding}}>{job.pfDate}</div>
                <div style={{width: "70px", padding: padding}}>{job.consensusId}</div>
            </div>;
        }) : <div style={{width: "320px", padding: padding}}>{jobsMessage}</div>}
        <Modal
            show={showModal}
            onHide={() => hideModal()}
            backdrop="static"
            keyboard={false}
            size={"lg"}
            aria-labelledby="terms-and-conditions"
            scrollable={true}
        >
            <Modal.Body>
                <div className={"alert import_log"}>
                    {logs && logs.map(message => {
                        return <div className={"import_log_" + message.key}>{message.value}</div>
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => hideModal()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </div>;
}

export function SendAfterSubscription() {

    const stompClient = useStompClient();

    if (stompClient) {
        stompClient.publish({destination: "/app/job-queue", body: 'subscribed'});
    } else {
        // Handle error
    }

    return '';
}
