import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {authHeader} from "../services/auth-header";
import {COMPUTING_CONTEXT_URL} from "../containers/canopia/CanopiaUtils";

const API_URL = COMPUTING_CONTEXT_URL + "api/admin/";

const initialState = {
    dates: [],
    dateStatus: "idle",
    dateError: null
}

export const loadDates = createAsyncThunk(
    'date/load',
    async () =>
        fetch(API_URL + "dates", {
            headers: authHeader(),
            cache: 'no-store'
        })
            .then(response => {
                let contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    console.log("The content type is not JSON, found " + contentType);
                }
            })
            .then(json => json)
            .catch(error => error)
);

export const dateSlice = createSlice({
    name: 'date',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadDates.pending, (state, action) => {
                state.dateStatus = 'loading';
            })
            .addCase(loadDates.fulfilled, (state, action) => {
                let payload = action.payload;
                if (payload.status) {
                    state.dateStatus = 'error';
                    state.dateError = payload;
                } else if (payload.message === 'Failed to fetch') {
                    state.dateStatus = 'error';
                    state.dateError = {
                        error: 'Service unavailable', //
                        message: 'We apologize for the inconvenience, our team is working on solving the issue. ' + //
                            'Please try to come back in a few minutes. Thank you for your patience.'
                    };
                } else {
                    state.dateStatus = 'success';
                    state.dates = payload["data"];
                }
            })
            .addCase(loadDates.rejected, (state, action) => {
                state.dateStatus = 'error';
                state.dateError = action.payload;
            })
    }
});

// export const {} = dateSlice.actions;

export const selectDateState = state => state.date;

export default dateSlice.reducer;