import * as PropTypes from "prop-types";
import FlatSelect from "../canopia/FlatSelect";
import React from "react";

PageSelector.propTypes = {
    page: PropTypes.number,
    pageSize: PropTypes.number,
    size: PropTypes.number,
    clickFunc: PropTypes.func
};

export function PageSelector(props) {

    const page = props.page;
    const pageSize = props.pageSize;
    const size = props.size;
    const clickFunc = props.clickFunc;

    const from = ((page - 1) * pageSize) + 1;
    const to = Math.min(page * pageSize, size);
    const details = <div style={{marginRight: '2rem'}} className={'nowrap'}>{from} - {to} / {size}</div>;

    if (size <= 0) {
        return 'No result';
    }

    if (size <= pageSize) {
        return <>{details}</>;
    }

    const pages = Math.ceil(size / pageSize);
    let items = [];
    let displayedPages = [];
    let ellipsis1 = false, ellipsis2 = false;
    for (let i = 1; i <= pages; i++) {
        if (i === 1 || i === pages || (i > page - 2 && i < page + 2)) {
            if (i > 1 && i < page && !ellipsis1 && !displayedPages.includes(i - 1)) {
                items.push({key: -1, label: '...', disabled: true});
                ellipsis1 = true;
            }
            if (i > page && !ellipsis2 && !displayedPages.includes(i - 1)) {
                items.push({key: -2, label: '...', disabled: true});
                ellipsis2 = true;
            }
            items.push(
                {key: i, label: i}
            );
            displayedPages.push(i);
        }
    }

    return (
        <div style={{display: 'flex', flexWrap: 'nowrap'}}>
            {details}
            <FlatSelect enums={items} curValue={{key: page, label: page}}
                        curClass={'canopia-nav-link-active'}
                        otherClass={'canopia-nav-link'}
                        clickFunction={clickFunc} clickParamFromEnum={true}/>
        </div>
    );
}

