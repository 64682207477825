import React, {useState} from "react";
import Table from "react-bootstrap/Table";
import {useDispatch, useSelector} from 'react-redux';
import {
    changeHistoPeriodicity,
    changeSelectedAmountField,
    focusOnRow,
    selectPortfolioState
} from '../../reducer/portfolioSlice';
import PortfolioHistoRow from "./PortfolioHistoRow";
import {
    VIEW_MODES,
    EMPTY_DATE,
    getAllAmountControls,
    getThemes,
    HISTO_SIZE,
    PERIODICITY_VALUES,
    THEMES,
    TOTAL,
    CO2, CANOPIA_REVIEW
} from "./CanopiaUtils";
import ESGMetricsHeaders from "./ESGMetricsHeaders";
import FlatSelect from "./FlatSelect";
import {DropdownButton} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import {selectClientState} from "../../reducer/clientSlice";
import LabelWithTooltip from "./LabelWithTooltip";
import {Filter, X} from "react-bootstrap-icons";
import AuthService from "../../services/auth.service";

export default function PortfolioHisto(props) {

    const showSuitability = props.showSuitability;
    const searchCategoryInput = props.searchInput;
    const viewSelect = props.viewSelect;
    const dateSelect = props.dateSelect;
    const changePfDate = props.changePfDate;

    const [theme, setTheme] = useState(THEMES[0]);
    const [subTheme, setSubTheme] = useState(THEMES[0].subThemes[0]);
    const [subThemeValue, setSubThemeValue] = useState(TOTAL);
    // const [showOldPos, setShowOldPos] = useState(true);
    const showOldPos = true;

    // TODO should be able to configure the default view
    // const [bmClassName, setBmClassName] = useState('bm-display');
    const [viewMode, setViewMode] = useState(VIEW_MODES[0]);

    const colorClass = 'canopia2';
    const bmColorClass = 'canopia2';
    const borderLeftClassName = 'canopia-border-left';
    const pfBgClass = 'background-light';
    const bmBgClass = 'background-light';

    const periodicityValues = PERIODICITY_VALUES;
    // const [clearSearchVisibility, setClearSearchVisibility] = useState('hidden');

    const dispatch = useDispatch();

    const {clientConfig} = useSelector(selectClientState);
    const {
        pfData,
        selectedAmountField,
        pfReportsAll,
        curRowKeyFocus,
        date,
        histoDates,
        periodicity
    } = useSelector(selectPortfolioState);

    const user = AuthService.getCurrentUser();
    const isReview = user && user.roles.includes(CANOPIA_REVIEW);
    const isDev = user && user.roles.includes("Canopia-Dev");

    if (!pfData) {
        return <div>No data found</div>;
    }

    const themes = getThemes(clientConfig);
    const amountFields = getAllAmountControls(clientConfig.showAmounts, clientConfig.clientWrapper.currency);

    // let histoLength = histoDates.length;
    let histoLength = HISTO_SIZE;

    let lastRootPf;
    let pfRows = [];
    Object.values(pfReportsAll).forEach((rootPf) => {
        if (date === rootPf.dateFmt) {
            lastRootPf = rootPf;
        }
        pfRows.push(rootPf);
    });

    // Amount data
    function changeAmountField(field) {
        dispatch(changeSelectedAmountField({amountField: field}));
    }

    const amountSelect = <FlatSelect enums={amountFields} curValue={selectedAmountField}
                                     curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
                                     clickFunction={changeAmountField} clickParamFromEnum={true}
                                     divStyle={{float: 'right'}}/>

    // Portfolio / Benchmark view
    function changeViewMode(viewMode) {
        setViewMode(viewMode);
    }

    // Themes
    function changeTheme(theme) {
        setTheme(theme);
        changeSubTheme(theme.subThemes[0]);
    }

    function changeSubTheme(theme) {
        setSubTheme(theme);
        clearSubThemeValue();
    }

    function changeSubThemeValue(v) {
        setSubThemeValue(v);
    }

    function clearSubThemeValue() {
        setSubThemeValue(TOTAL);
    }

    // Focus (filter)
    function clearFocus() {
        dispatch(focusOnRow({key: null}));
    }

    const pfBmSelect = <FlatSelect enums={showSuitability ? VIEW_MODES : VIEW_MODES.slice(0, 3)} curValue={viewMode}
                                   curClass={'canopia-nav-link-active'}
                                   otherClass={'canopia-nav-link'}
                                   clickFunction={changeViewMode} clickParamFromEnum={true}/>

    let periodicitySelect = <DropdownButton id="dropdown-basic-button" title={periodicity.label} value={periodicity.key}
                                            variant={'default-dropdown'}>
        {periodicityValues.map(periodicityValue => (
            periodicityValue.key !== periodicity.key &&
            <DropdownItem key={periodicityValue.key} value={periodicityValue.key}
                          onClick={() => changePeriodicity(periodicityValue)} bsPrefix={'canopia-dropdown-item'}>
                <span style={{fontWeight: 'normal'}}>{periodicityValue.label}</span>
            </DropdownItem>
        ))}
    </DropdownButton>;

    const changePeriodicity = (periodicityValue) => {
        // resetSearch();
        dispatch(changeHistoPeriodicity({periodicityValue: periodicityValue}));
    }

    let esgMetricsH1;
    let esgMetricsH2;
    let nbCols = 0;
    let bmContent = <></>;
    const isSingleFieldView = theme.subThemesHistoView === 'singleField';
    // let rows = <PortfolioRow viewMode={viewMode} row={pfReportDashboard} depth={1} bmClassName={bmClassName} themes={themes} theme={theme}/>;
    const rows = <PortfolioHistoRow key={lastRootPf.key} viewMode={viewMode} rows={pfRows} depth={1}
                                    rowKey={lastRootPf.key} showOldPos={showOldPos}
                                    themes={themes}
                                    theme={theme} subTheme={subTheme} subThemeValue={subThemeValue}
                                    changeTheme={changeTheme}
                                    changeSubTheme={changeSubTheme}
                                    changeSubThemeValue={changeSubThemeValue}
                                    clearSubThemeValue={clearSubThemeValue}
                                    dateSelect={dateSelect}
                                    changePfDate={changePfDate}
                                    isReview={isReview}
                                    isDev={isDev}
    />;
    switch (viewMode.key) {
        case "pf":
            esgMetricsH1 =
                <>
                    {themes.map(theme => {
                        let key = 'h1_' + theme.key;
                        if (theme.mainViewShow) {
                            let nbCols = 0;
                            theme.subThemes.forEach(subTheme => {
                                if (subTheme.mainViewShow) {
                                    nbCols += subTheme.cols;
                                }
                            });
                            return <React.Fragment key={key}>
                                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}
                                    colSpan={nbCols}>
                                    {theme.label}
                                </th>
                            </React.Fragment>
                        } else {
                            return <React.Fragment key={key}/>
                        }
                    })}
                </>

            esgMetricsH2 = <>
                {themes.map(theme => {
                    let key = 'db_esg_h2_' + theme.key;
                    if (theme.mainViewShow) {
                        return <ESGMetricsHeaders key={key}
                                                  theme={theme}
                                                  themes={themes}
                                                  borderLeftClassName={borderLeftClassName}
                                                  bgClass={pfBgClass}
                                                  showBm={false}
                                                  viewModeKey={viewMode.key}/>
                    } else {
                        return <React.Fragment key={key}/>
                    }
                })}
            </>

            themes.forEach(theme => {
                if (theme.mainViewShow) {
                    theme.subThemes.forEach(subTheme => {
                        if (subTheme.mainViewShow) {
                            nbCols += subTheme.cols;
                        }
                    });
                }
            });
            break;
        case "bm":
            nbCols = ((isSingleFieldView ? 1 : 2) * theme.cols) + 1;

            esgMetricsH1 = <>
                <th className={colorClass + " " + pfBgClass + " canopia-border-left"} colSpan={nbCols}>
                    <FlatSelect enums={themes} curValue={theme} curClass={'canopia-nav-link-active'}
                                otherClass={'canopia-nav-link'}
                                clickFunction={changeTheme} clickParamFromEnum={true}/>
                </th>
            </>

            esgMetricsH2 = <ESGMetricsHeaders
                theme={theme}
                subTheme={subTheme}
                themes={themes}
                changeSubThemeFunc={changeSubTheme}
                borderLeftClassName={'canopia-border-left'}
                bgClass={pfBgClass}
                showBm={true}
                viewModeKey={viewMode.key}/>

            bmContent = <>
                <ESGMetricsHeaders
                    theme={theme}
                    themes={themes}
                    showBm={true}
                    type={'bm'}
                    bgClass={bmBgClass}
                    viewModeKey={viewMode.key}
                    borderLeftClassName={'border-large-left'}/>
                <th className={bmColorClass + " " + bmBgClass + " " + borderLeftClassName}>Benchmark</th>
            </>;
            break;
        case "hi":

            nbCols = (isSingleFieldView ? 1 : theme.cols) * histoLength;

            esgMetricsH1 = <>
                <th className={colorClass + " " + pfBgClass + " canopia-border-left"} colSpan={nbCols}>
                    <FlatSelect enums={themes} curValue={theme} curClass={'canopia-nav-link-active'}
                                otherClass={'canopia-nav-link'}
                                clickFunction={changeTheme} clickParamFromEnum={true}/>
                </th>
            </>

            esgMetricsH2 = histoDates.map(d => {
                const key = "header1_esg_review_histo_" + d;
                if (d === date) {
                    return <ESGMetricsHeaders key={key}
                                              theme={theme}
                                              subTheme={subTheme}
                                              themes={themes}
                                              changeSubThemeFunc={changeSubTheme}
                                              showBm={true}
                                              borderLeftClassName={'canopia-border-left'}
                                              bgClass={pfBgClass}
                                              viewModeKey={viewMode.key}/>;
                } else {
                    return <th key={key}
                               className={colorClass + " " + pfBgClass + " histo-single-field " + borderLeftClassName}
                               colSpan={isSingleFieldView ? subTheme.cols : theme.cols}>
                        {!d.startsWith(EMPTY_DATE) && <div style={{float: "right", whiteSpace: "nowrap"}}>{d}</div>}
                    </th>
                }
            });
            break;
        case "su":
            esgMetricsH1 = <>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={2}>
                    Final Grade
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                    ESG Review
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                    Controversies
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={2}>
                    Impacts
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                    {CO2}
                </th>
            </>

            esgMetricsH2 = <>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                    Portfolio
                </th>
                <th className={colorClass + " " + pfBgClass}>
                    Benchmark
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} style={{minWidth: '110px'}}>
                    ESG Grade
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} style={{minWidth: '110px'}}>
                    Exclusions
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} style={{minWidth: '110px'}}>
                    Positive
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} style={{minWidth: '110px'}}>
                    Negative
                </th>
                <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} style={{minWidth: '110px'}}>
                    {CO2}
                </th>
            </>

            nbCols = 7;
            break;
        default:
        //

    }

    // function showHideOldPos() {
    //     setShowOldPos(!showOldPos);
    // }

    // let message = <></>;
    // let rows = <></>;
    // if (histoLength <= 1) {
    //     message = <Card style={{margin: 'auto', zIndex: -1}}>
    //         <Card.Body>
    //             <Card.Title className='canopia2'>The historical view requires at least 2 dates to be displayed.</Card.Title>
    //         </Card.Body>
    //     </Card>;
    // } else {
    //     rows = <PortfolioHistoRow key={lastRootPf.category} rows={pfRows} depth={1} rowKey={lastRootPf.category} parentKey={""} theme={theme} subTheme={subTheme}/>;
    // }

    // const headerNbCols = isSingleFieldView ? theme.key === 'co2' ? histoLength + 1 : histoLength : histoLength * theme.cols;

    return (
        <div>
            <Table hover size="sm" className={'portfolio sticky-thc'}>
                <thead>
                <tr>
                    <th className={colorClass + " " + pfBgClass} style={{width: '100px'}}>
                        Portfolio
                    </th>
                    <th className={colorClass + " " + pfBgClass + " form-padding"} colSpan={2}
                        style={{width: '20%', minWidth: '240px'}}>
                        <span style={{float: 'left'}}>{viewSelect}</span>
                    </th>
                    <th className={colorClass + " " + pfBgClass + " form-padding"} colSpan={2} style={{width: '10%'}}>
                        {/*{viewMode.key === 'hi' && <span onClick={showHideOldPos}>Show old positions*/}
                        {/*    <span style={{marginLeft: '8px'}}>*/}
                        {/*        {showOldPos ? <CheckSquareFill color={'#adad2f'} size={'1.4em'}/> : <Square color={'#adad2f'} size={'1.4em'}/>}*/}
                        {/*    </span>*/}
                        {/*</span>}*/}
                    </th>
                    <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={nbCols}>
                        {pfBmSelect}
                    </th>
                </tr>
                <tr>
                    <th className={colorClass + " " + pfBgClass}>
                        Holdings
                    </th>
                    <th className={colorClass + " " + pfBgClass + " form-padding"} colSpan={2}>
                        <span style={{float: 'left'}}>{dateSelect}</span>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        {viewMode.key === 'hi' && 'Periodicity'}
                    </th>
                    <th className={colorClass + " " + pfBgClass + " form-padding"}>
                        {viewMode.key === 'hi' && <span style={{float: 'right'}}>{periodicitySelect}</span>}
                    </th>
                    {esgMetricsH1}
                </tr>
                <tr>
                    <th className={colorClass + " " + pfBgClass}>Category</th>
                    <th className={colorClass + " " + pfBgClass + " form-padding"}>
                        {!curRowKeyFocus.length && searchCategoryInput}
                    </th>
                    {/*<th className={colorClass + " " + pfBgClass}><img alt={'pdf'} src={'/PDF_file_icon.svg'} width={ICON_SIZE} height={ICON_SIZE}/></th>*/}
                    <th className={colorClass + " " + pfBgClass}>
                        {curRowKeyFocus.length > 0 &&
                            <LabelWithTooltip label={
                                <span onClick={clearFocus}>
                                    <Filter className={"react-icons canopia"} size={'1.4em'}/>
                                    <X className={"react-icons canopia6"} size={'1.6em'}
                                       style={{paddingBottom: "2px", marginLeft: "-14px", marginBottom: "-7px"}}/>
                                </span>} text={'Clear all filters'} className={'canopia-icon-with-legend'}>
                            </LabelWithTooltip>}
                    </th>
                    <th className={colorClass + " " + pfBgClass}>Positions</th>
                    <th className={colorClass + " " + pfBgClass}>{amountSelect}</th>
                    {esgMetricsH2}
                    {bmContent}
                    {/*{histoDates.map(date => {*/}
                    {/*    return <th key={"header1_esg_review_histo_" + date} className={colorClass + " " + pfBgClass + " " + borderLeftClassName}*/}
                    {/*               colSpan={isSingleFieldView ? subTheme.cols : theme.cols} style={{minWidth: '100px'}}>*/}
                    {/*        {!date.startsWith(EMPTY_DATE) && <div style={{float: "right", whiteSpace: "nowrap"}}>{date}</div>}*/}
                    {/*    </th>*/}
                    {/*})}*/}
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Table>
            {/*{message}*/}
        </div>
    );
}
