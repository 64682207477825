import React from "react";
import DetailHistoGradeChart from "./DetailHistoGradeChart";
import DetailSubGradeChart from "./DetailSubGradeChart";
import Coverage from "../Coverage";
import {AMOUNT_THEME, consensusGradeText, finalGradeText, formatPct, getValue, LEGENDS} from "../CanopiaUtils";
import ConsensusColumnChart from "./ConsensusColumnChart";
import ConsensusGrade from "../ConsensusGrade";
import {Asterisk} from "react-bootstrap-icons";
import LabelWithTooltip from "../LabelWithTooltip";
import FinalGrade from "../FinalGrade";
import DetailHistoAmountChart from "./DetailHistoAmountChart";
import {useSelector} from "react-redux";
import {selectClientState} from "../../../reducer/clientSlice";

export default function GradeChart(props) {

    const pfRow = props.pfRow;
    const pfEsgReport = pfRow ? pfRow.esgReport : null;
    const bmRow = props.bmRow;
    const bmEsgReport = bmRow ? bmRow.esgReport : null;
    const theme = props.theme;
    const subTheme = props.subTheme;
    // const subThemeValue = props.subThemeValue;
    // const clearSubThemeValue = props.clearSubThemeValue;
    const changePfKey = props.changePfKey;
    const pfName = props.pfName;
    const bmName = props.bmName;
    const date = props.date;
    const maxDate = props.maxDate;
    const isReview = props.isReview;

    const data = props.data;
    const chartData = data[subTheme.key];

    // const pfChartDiv = "chart-pf-rating";
    // const bmChartDiv = "chart-bm-rating";
    // const tgtChart = useRef(null);
    // const consChartDiv = "chart-cons-rating";

    const subThemeValue = subTheme.valueFields[0];

    const {clientConfig} = useSelector(selectClientState);

    let pfGradeIconLarge = "";
    let pfConsGradeIconSmall = "";
    let pfGradeAdj = 0;
    let pfCoverage = "";
    let pfConsAverage = "";
    let pfConsStrength = "";

    let bmGradeIconLarge = "";
    let bmConsGradeIconSmall = "";
    let bmGradeAdj = 0;
    let bmCoverage = "";
    let bmConsAverage = "";
    let bmConsStrength = "";
    // const finalGradeMessage = 'Final grade adjusted due to the threshold reached by controversies and/or impacts (see methodology for details)';

    const finalGradeSize = 50;
    const adjIconSize = '0.8em';
    if (pfEsgReport && pfEsgReport.rating && pfEsgReport.rating.trim() !== "") {

        pfCoverage = pfEsgReport.covFmt;
        pfConsAverage = formatPct(getValue(pfEsgReport, "consensusWgt"));
        pfConsStrength = formatPct(getValue(pfEsgReport, "strengthWgt"));

        // Final grade
        // let gradeIconPath = getIconPath(pfEsgReport.rating);
        // pfGradeIconLarge =
        //     <img alt={pfEsgReport.rating} src={gradeIconPath} width={finalGradeSize} height={finalGradeSize}/>;
        pfGradeIconLarge =
            <FinalGrade rating={pfEsgReport.rating} rank={pfEsgReport.ratingRank} size={finalGradeSize}/>;

        // Consensus grade
        if (pfEsgReport.consensusRating && pfEsgReport.consensusRating.trim() !== "") {
            pfConsGradeIconSmall =
                <ConsensusGrade rating={pfEsgReport.consensusRating} rank={pfEsgReport.consRatingRank}/>;

            pfGradeAdj = pfEsgReport.ratingRank - pfEsgReport.consRatingRank;
        }
    }
    if (bmEsgReport && bmEsgReport.rating && bmEsgReport.rating.trim() !== "") {

        bmCoverage = bmEsgReport.covFmt;
        bmConsAverage = formatPct(getValue(bmEsgReport, "consensusWgt"));
        bmConsStrength = formatPct(getValue(bmEsgReport, "strengthWgt"));

        // Final grade
        // let bmGradeIconPath = getIconPath(bmEsgReport.rating);
        // bmGradeIconLarge =
        //     <img alt={bmEsgReport.rating} src={bmGradeIconPath} width={finalGradeSize} height={finalGradeSize}/>;
        bmGradeIconLarge =
            <FinalGrade rating={bmEsgReport.rating} rank={bmEsgReport.ratingRank} size={finalGradeSize}/>;

        // Consensus grade
        if (bmEsgReport.consensusRating && bmEsgReport.consensusRating.trim() !== "") {
            bmConsGradeIconSmall =
                <ConsensusGrade rating={bmEsgReport.consensusRating} rank={bmEsgReport.consRatingRank}/>;

            bmGradeAdj = bmEsgReport.ratingRank - bmEsgReport.consRatingRank;
        }
    }

    let theChart = <>
        <div className={"esg_review_detail_container"}>
            <div className={"esg_review_detail_row"}>
                <div className={'canopia2 detail-sub-title esg_review_detail_final_grade'}>
                    <LabelWithTooltip label={'Final Grade'} text={finalGradeText}/>
                </div>
                <div className={'canopia2 detail-sub-title esg_review_detail_chart'}>
                    <LabelWithTooltip label={'Consensus Grade'} text={consensusGradeText}/>
                </div>
                <div className={'canopia2 detail-sub-title esg_review_detail_coverage'}>
                    <LabelWithTooltip label={'Coverage'} text={LEGENDS['esgReport.coverage']}/>
                </div>
            </div>

            <div className={"esg_review_detail_row"}>
                <div className={"esg_review_detail_final_grade"}
                     style={{display: "grid", gridTemplateColumns: "1fr " + (finalGradeSize + 15) + "px"}}>
                    <div style={{margin: "12px 3px"}}>{pfName}</div>
                    <div style={{display: 'flex'}}>
                        {pfGradeIconLarge}
                        {/*{pfGradeAdj > 0 ? <IoMdArrowRoundUp color={'#adad2f'} size={'1.8em'}*/}
                        {/*                                    style={{verticalAlign: 'top'}}/> : pfGradeAdj < 0 &&*/}
                        {/*    <IoMdArrowRoundDown color={'#adad2f'} size={'1.8em'} style={{verticalAlign: 'bottom'}}/>}*/}
                        {pfGradeAdj !== 0 && <Asterisk size={adjIconSize}/>}
                    </div>
                </div>
                <div className={"esg_review_detail_chart"} style={{display: "flex", flexWrap: "wrap"}}>
                    <div style={{margin: "8px 20px", width: "30px"}}>{pfConsGradeIconSmall}</div>
                    <ConsensusColumnChart esgReport={pfEsgReport} width={'82%'}/>
                    <div style={{margin: "8px 20px", width: "30px"}}/>
                    <div style={{margin: "2px 10px"}} className={'box-light'}>Consensus average: {pfConsAverage}</div>
                    <div style={{margin: "2px 10px"}} className={'box-light'}>Consensus strength: {pfConsStrength}</div>
                </div>
                <div className={"esg_review_detail_coverage"} style={{display: "flex"}}>
                    <div style={{marginTop: "15px", marginLeft: "20px"}}><Coverage esgReport={pfEsgReport}/></div>
                    <div style={{margin: "12px 0 0 10px", textAlign: 'left'}}>{pfCoverage}</div>
                </div>
            </div>

            {bmName &&
                <div className={"esg_review_detail_row"}>

                    <div className={"esg_review_detail_final_grade"}
                         style={{display: "grid", gridTemplateColumns: "1fr " + (finalGradeSize + 15) + "px"}}>
                        <div style={{margin: "12px 3px"}}>{bmName}</div>
                        <div style={{display: 'flex'}}>
                            {bmGradeIconLarge}
                            {/*{pfGradeAdj > 0 ? <IoMdArrowRoundUp color={'#adad2f'} size={'1.8em'}*/}
                            {/*                                    style={{verticalAlign: 'top'}}/> : pfGradeAdj < 0 &&*/}
                            {/*    <IoMdArrowRoundDown color={'#adad2f'} size={'1.8em'} style={{verticalAlign: 'bottom'}}/>}*/}
                            {bmGradeAdj !== 0 && <Asterisk size={adjIconSize}/>}
                        </div>
                    </div>
                    <div className={"esg_review_detail_chart"} style={{display: "flex", flexWrap: "wrap"}}>
                        <div style={{margin: "8px 20px", width: "30px"}}>{bmConsGradeIconSmall}</div>
                        <ConsensusColumnChart esgReport={bmEsgReport} width={'82%'}/>
                        <div style={{margin: "8px 20px", width: "30px"}}/>
                        <div style={{margin: "2px 10px"}} className={'box-light'}>Consensus
                            average: {bmConsAverage}</div>
                        <div style={{margin: "2px 10px"}} className={'box-light'}>Consensus
                            strength: {bmConsStrength}</div>
                    </div>
                    <div className={"esg_review_detail_coverage"} style={{display: "flex"}}>
                        <div style={{marginTop: "15px", marginLeft: "20px"}}><Coverage esgReport={bmEsgReport}/></div>
                        <div style={{margin: "12px 0 0 10px", textAlign: 'left'}}>{bmCoverage}</div>
                    </div>
                </div>}
        </div>

        <div>
            <DetailSubGradeChart pfRow={pfRow} bmRow={bmRow} data={chartData} theme={theme} subTheme={subTheme}
                                 subThemeValue={subThemeValue}
                                 changePfKey={changePfKey}
                                 pfName={pfName} bmName={bmName} date={date} maxDate={maxDate} isReview={isReview}/>
            {/*<SectorSubBarChart pfRow={pfRow} bmRow={bmRow} data={data} subTheme={subTheme} sector={sector} dataScale={dataScale}*/}
            {/*                   changeDataScale={changeDataScale}*/}
            {/*                   pfName={pfName} bmName={bmName} date={date} maxDate={maxDate} isReview={isReview}/>*/}

        </div>
        <div className={'canopia2 detail-sub-title'}>
            <LabelWithTooltip label={'Final Grade history'} text={LEGENDS['detail.finalGradeHistory']}/>
        </div>
        <DetailHistoGradeChart data={chartData} theme={theme} subTheme={subTheme}
                               pfName={pfName} bmName={bmName} maxDate={maxDate} isReview={isReview}/>
        {clientConfig.showAmounts === true && isReview && <DetailHistoAmountChart data={data[AMOUNT_THEME.subThemes[0].key]} pfName={pfName} bmName={bmName} subTheme={AMOUNT_THEME.subThemes[0]}
                                             categoryField={AMOUNT_THEME.subThemes[0].valueFields[0]}
                                             maxDate={maxDate} isReview={isReview}/>}
    </>;

    return <>
        {theChart}
        {/*{!isReview && <span className={'label-hover'} onClick={exportAsImage} style={{position: "absolute", top: 65, right: 30}}>*/}
        {/*    <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>*/}
        {/*        <AiOutlineDownload/>*/}
        {/*    </IconContext.Provider>*/}
        {/*</span>}*/}
    </>
}
